import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import NotFound from "../Image/not_found.png";
import { Bars } from "react-loader-spinner";
import {
  AddTechnology,
  DeleteTechnology,
  GetTechnology,
  UpdateTechnology,
} from "../helper/auth";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSymbol } from "../provider/ContextProvider";

const Membership = () => {
  const { register, handleSubmit, reset } = useForm();
  const [MainTech, setMainTech] = useState([]);
  const { Symbol } = useSymbol();
  const [isLoad, setisLoad] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [isUpdate, setisUpdate] = useState(false);
  const [Techs, setTechs] = useState([]);
  const [cookie] = useCookies(["users"]);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [UpdateData, setUpdateData] = useState(null);
  const GetTech = async () => {
    try {
      const obj = { user_id: cookie.users };
      const Main = await GetTechnology(obj);
      setMainTech(Main.data);

      const indexOfLastRecord = currentPage * recordsPerPage;
      const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      const currentRecords = Main.data.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      );
      setTechs(currentRecords);
      const nPages = Math.ceil(Main.total_records / recordsPerPage);
      const num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
    } catch (er) {
      console.log(er);
    } finally {
      setisLoad(true);
    }
  };
  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = MainTech.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setTechs(currentRecords);
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    const newArray = MainTech.filter((item) =>
      item.technology.toLowerCase().includes(val.toLowerCase())
    );
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setTechs(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };
  const OnAddTechnology = (data) => {
    const obj = { user_id: cookie.users, ...data };
    toast
      .promise(AddTechnology(obj), {
        loading: "Wait Few Moment !",
        success: "Technology Add SuccessFully !",
        error: (Er) => Er,
      })
      .then((val) => {
        document.getElementById("closeAdd").click();
        setisUpdate((prev) => !isUpdate);
        reset();
      });
  };

  const OnEdit = () => {
    const obj = {
      technology_id: UpdateData.id,
      technology: UpdateData.technology,
      hours: UpdateData.hourse,
    };
    toast
      .promise(UpdateTechnology(obj), {
        loading: "Wait Few Moment !",
        success: "Technology Update SuccessFully !",
        error: (Er) => Er,
      })
      .then((val) => {
        document.getElementById("closeEdit").click();
        setisUpdate(!isUpdate);
      });
  };

  const handleDeleteTech = (id) => {
    const obj = {
      technology_id: id,
    };
    toast
      .promise(DeleteTechnology(obj), {
        loading: "Wait Few Moment !",
        success: "Technology Delete SuccessFully !",
        error: (Er) => Er,
      })
      .then((val) => {
        if (Techs?.length == 1) {
          setMainTech([]);
          setTechs([]);
        }
        setisUpdate(!isUpdate);
      });
  };

  const handleEditData = (data) => {
    setUpdateData(data);
  };
  useEffect(() => {
    GetTech();
  }, [isUpdate]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>
                    Technology <span>{MainTech?.length ?? 0}</span>
                  </p>
                  <Link
                    type="button"
                    className="btn add-new"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i className="fa-solid fa-plus"></i> Add New
                  </Link>
                </div>
                {/* <!-- Add Modal --> */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Technology
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="user-details">
                          <form onSubmit={handleSubmit(OnAddTechnology)}>
                            <div className="form-floating mb-4 mt-2">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Tech Name "
                                {...register("technology", { required: true })}
                              />
                              <label for="floatingInput">Technology</label>
                            </div>
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Hours"
                                {...register("hours", { required: true })}
                              />
                              <label for="floatingInput">
                                Per Hours Rate ( in {Symbol ?? "$"})
                              </label>
                            </div>
                            <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                              <button type="submit" className="btn btn-upload">
                                Save changes
                              </button>
                              <button
                                type="button"
                                className="btn btn-reset me-0"
                                data-bs-dismiss="modal"
                                id="closeAdd"
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--End Add Modal --> */}
                {/* <!-- Edit Modal --> */}
                <div
                  className="modal fade"
                  id="exampleModaledit"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Edit Technology
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="user-details">
                          <div>
                            <div className="form-floating mb-4 mt-2">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                value={UpdateData?.technology}
                                onChange={(e) =>
                                  setUpdateData({
                                    ...UpdateData,
                                    technology: e.target.value,
                                  })
                                }
                              />
                              <label for="floatingInput">Technology</label>
                            </div>
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                value={UpdateData?.hourse}
                                onChange={(e) =>
                                  setUpdateData({
                                    ...UpdateData,
                                    hourse: e.target.value,
                                  })
                                }
                              />
                              <label for="floatingInput">
                                Per Hours Rate ( in {Symbol ?? "$"})
                              </label>
                            </div>
                            <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                              <button
                                className="btn btn-upload"
                                onClick={OnEdit}
                              >
                                Save changes
                              </button>
                              <button
                                type="button"
                                className="btn btn-reset me-0"
                                data-bs-dismiss="modal"
                                id="closeEdit"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--End Edit Modal --> */}
                <div className="">
                  <div className="p-0 d-flex justify-content-end">
                    <div className="form-floating small-floating mb-3 text-end">
                      <input
                        type="text"
                        className="form-control py-2"
                        id="floatingInput"
                        placeholder="name@example.com"
                        onChange={handleSearch}
                      />
                      <label for="floatingInput">Search</label>
                    </div>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Technology</th>
                      <th scope="col">Per Hours Rate</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoad ? (
                      Techs?.length ? (
                        Techs?.map((val, index) => {
                          return (
                            <tr key={val.id}>
                              <th scope="row">
                                {currentPage != 1
                                  ? 10 * (currentPage - 1) + index + 1
                                  : index + 1}
                              </th>
                              <td className="td-technology">
                                {val.technology}
                              </td>
                              <td>
                                {Symbol || "$"} {val.hourse}
                              </td>
                              <td>
                                <div className="icon-up-del">
                                  <Link
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModaledit"
                                    onClick={() => {
                                      handleEditData(val);
                                    }}
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                  </Link>
                                  <Link
                                    onClick={() => handleDeleteTech(val.id)}
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4}>
                            <div className=" ">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column-reverse",
                                  alignItems: "center",
                                }}
                              >
                                <p>Technology Not Found</p>
                                <div>
                                  <img src={NotFound} width={250} />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <div className=" ">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                                alignItems: "center",
                              }}
                            >
                              <Bars
                                height="50"
                                width="50"
                                color="#C256FA"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="pro-add-new px-0 mb-0 pt-3">
                  <p>
                    {currentPage} - {nPage?.length} of {nPage?.length}
                  </p>
                  <nav aria-label="...">
                    <ul className="pagination pagination-sm mb-0">
                      {nPage?.map((val) => {
                        return (
                          <li
                            style={{ cursor: "pointer" }}
                            className={`page-item ${
                              currentPage == val && "active"
                            }`}
                            aria-current="page"
                            key={val}
                            onClick={() => handleChangePage(val)}
                          >
                            <span className="page-link">{val}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default Membership;
