import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { AddProjectApi, GetModuleForProject } from "../helper/Main";
import { useCookies } from "react-cookie";
import { AddModuleApi, GetModuleAPi, GetTechnology } from "../helper/auth";
import toast from "react-hot-toast";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { USER_INFO } from "../utils/Api";
import logo from "../logo.svg";
import { useSymbol } from "../provider/ContextProvider";

let AllMod = {};
let Technologys = [];

const CreateProject = () => {
  const navigate = useNavigate();
  const [isLoad, setisLoad] = useState(false);
  const [UserDetails, setUserDetails] = useState(null);
  const [activeLinks, setActiveLinks] = useState([]);
  const [IsUpdate, setIsUpdate] = useState(false);
  const [BaseOnHourse, setBaseOnHourse] = useState(false);
  const [ClientTechs, setClientTechs] = useState([]);
  const [AllModule, setAllModule] = useState([]);
  const [TechPrize, setTechPrize] = useState({});
  const [ClientModule, setClientModule] = useState([]);
  const [Filter, setFilter] = useState([]);
  const { Symbol } = useSymbol();
  const [TechIds, setTechIds] = useState([]);
  const [Techs, setTech] = useState([]);
  const [module, setmodule] = useState([]);
  const [mainModule, setmainModule] = useState([]);
  const [ClientName, setClientName] = useState("");
  const [Project, setProject] = useState("");
  const [cookie] = useCookies(["users"]);
  const [BillData, setBillData] = useState([]);
  const [TotalPrizeBill, setTotalPrizeBill] = useState(0);
  const [TotalHourseBill, setTotalHourseBill] = useState(0);
  const [SuggectionForModule, setSuggectionForModule] = useState([]);
  const [isFouse, setisFouse] = useState(false);
  const [isComplete, setisComplete] = useState(false);
  const [isDropDown, setisDropDown] = useState(false);
  const DropDownRef = useRef(null);
  const [Module, setModule] = useState({
    user_id: cookie.users,
    technology_id: null,
    module: null,
    hours_number: null,
    prize: null,
  });

  async function getData() {
    try {
      let obj = {
        user_id: cookie?.users,
      };
      const { data } = await axios.post(USER_INFO, obj);
      setUserDetails(data.user);
    } catch (er) {}
  }

  const handleLinkClick = (tech) => {
    // console.log("Clicked:", tech);
    setActiveLinks((prevActiveLinks) =>
      prevActiveLinks.includes(tech)
        ? prevActiveLinks.filter((link) => link !== tech)
        : [...prevActiveLinks, tech]
    );
    // console.log("Active Links:", activeLinks);
  };

  const handleAddModule = () => {
    if (
      !Module?.technology_id ||
      !Module?.module ||
      !Module?.hours_number ||
      !Module?.prize
    ) {
      return toast.error("All field must be required !");
    }
    toast
      .promise(AddModuleApi(Module), {
        loading: "Wait Few Moment !",
        success: "Technology Add Successfully !",
        error: (er) => er,
      })
      .then((val) => {
        setModule({
          ...Module,
          technology_id: null,
          module: null,
          hours_number: null,
          prize: null,
        });
        document.getElementById("cancelMod").click();
        setIsUpdate(!IsUpdate);
      })
      .catch(() => {});
  };

  const handleSearchData = (e) => {
    let { value } = e.target;
    const newArray = mainModule.filter((item) =>
      item?.module?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setmodule(newArray);
  };

  const handlePrizeGet = (name) => {
    let Prize = 0;
    TechIds?.map((val) => {
      if (val?.includes(name)) {
        if (BaseOnHourse) {
          let techNo = val?.replace(/[^0-9]/g, "");
          let num = TechPrize[techNo];
          let hourse = AllMod[val]?.[1];
          let sum = Number(num) * Number(hourse);
          Prize += sum;
        } else {
          let num = AllMod[val][0];
          Prize += Number(num);
        }
      }
    });
    return Prize;
  };

  const handleHourseGet = (name) => {
    let hourse = 0;
    TechIds?.map((val) => {
      if (val?.includes(name)) {
        const data = AllMod[val];
        hourse += Number(data[1]);
      }
    });
    return hourse;
  };

  async function getModule() {
    try {
      let obj = {
        user_id: cookie.users,
      };
      setisLoad(false);
      const data = await GetModuleForProject(obj);
      const Tech = await GetTechnology(obj);
      const Mod = await GetModuleAPi(obj);
      let SMOD = Mod.data?.map((item) => item?.module.trim());
      let demo = [...new Set(SMOD)];
      setSuggectionForModule(demo);
      let TechItem = {};
      Technologys = Tech?.data;
      Tech?.data?.map((item) => {
        TechItem[item?.id] = item?.hourse;
      });

      setTechPrize(TechItem);

      setTech(Tech.data);

      if (Filter?.length) {
        let newData = [];
        data?.data?.forEach((element) => {
          let check = element?.technology_names?.some((el) =>
            Filter?.includes(el?.technology_name)
          );
          if (check) {
            newData.push(element);
          }
        });
        setmodule(newData);
        setmainModule(newData);
      } else {
        setmodule(data.data);
        setmainModule(data.data);
      }
      setAllModule(Mod.data);
      Mod?.data.map((val) => {
        let pair = val?.module + val?.technology_id;
        AllMod[pair] = [val?.prize, val?.hours_number];
      });
    } catch (er) {
    } finally {
      setisLoad(true);
    }
  }

  const handleTotalPrice = () => {
    let Prize = 0;
    ClientModule?.map((item) => {
      let Total = handleTotalClient(item?.module);
      Prize += Total;
    });
    return Prize;
  };

  const handleGetHourse = (name) => {
    let Hourse = 0;
    ClientTechs?.map((val) => {
      if (val?.includes(name)) {
        let num = AllMod[val]?.[1];
        Hourse += Number(num);
      }
    });
    return Hourse;
  };

  const handleTotalClient = (name) => {
    let Prize = 0;
    ClientTechs?.map((val) => {
      if (val?.includes(name)) {
        if (BaseOnHourse) {
          let techNo = val?.replace(/[^0-9]/g, "");
          let num = TechPrize?.[techNo];
          let hourse = AllMod[val]?.[1];
          Prize += Number(num) * Number(hourse);
        } else {
          let num = AllMod?.[val]?.[0];
          Prize += Number(num);
        }
      }
    });
    return Prize;
  };
  const handleTotalHourse = () => {
    let hourse = 0;
    ClientModule?.map((val) => {
      let h = handleGetHourse(val?.module);
      hourse += h;
    });
    return hourse;
  };

  const HandleSubmitProject = async () => {
    try {
      if (!ClientModule?.length) {
        toast.error("Please Select Module");
      } else if (!ClientName || !Project) {
        toast.error("Please Enter ClientName or ProjectName !");
      } else if (
        !UserDetails?.organizations ||
        !UserDetails?.address ||
        !UserDetails?.zip ||
        !UserDetails?.state ||
        !UserDetails?.email
      ) {
        navigate("/About");
        toast.error(
          "Please fullfill user organizations or address or zip or state or email !"
        );
      } else {
        let ModuleId = ClientModule.map((val) => val?.module)?.join(",");
        let TechIds;
        ClientModule?.map((item) => {
          let Ids = "";
          ClientTechs?.map((val) => {
            if (val?.includes(item?.module)) {
              let str = val?.replace(/[^0-9]/g, "");
              console.log(str);
              !Ids?.length ? (Ids = str) : (Ids += "," + str);
            }
          });
          !TechIds?.length ? (TechIds = Ids) : (TechIds += " || " + Ids);
        });
        // console.log(TechIds);
        const total_hourse = handleTotalHourse();
        const total_price = handleTotalPrice();
        console.log(TechIds);
        let In = [];
        ClientModule?.map((item, index) => {
          let obj = {
            id: index,
            module: item?.module,
            total_hourse: handleGetHourse(item?.module),
            total_price: handleTotalClient(item?.module),
          };
          In.push(obj);
        });
        setBillData(In);
        setTotalPrizeBill(total_price);
        setTotalHourseBill(total_hourse);
        const obj = {
          user_id: cookie?.users,
          client_name: ClientName,
          client_project: Project,
          modules: ModuleId,
          techs: TechIds,
          base_on_hourse: BaseOnHourse,
          total_price,
          total_hourse,
        };
        const data = await toast.promise(AddProjectApi(obj), {
          loading: "Wait Few Moment !",
          error: "Error",
          success: "Project Add Successfully !",
        });

        navigate(`/Invoice/${data?.data}`);
      }
    } catch (er) {
      console.log(er);
    }
  };

  const handleAddProject = (data, check) => {
    const newData = TechIds.map((val) => {
      if (val?.includes(data?.module)) {
        return val;
      }
    });
    // console.log(newData);
    if (newData?.length && newData?.some((el) => el?.includes(data?.module))) {
      setClientTechs((prev) => [...prev, ...newData]);
      setClientModule((prev) => [...prev, data]);
      toast.success("Module add successfully !");
    } else {
      toast.error("Please Select Technology !");
    }
  };
  useEffect(() => {
    getModule();
    getData();
  }, [IsUpdate]);

  useEffect(() => {
    const handleOutSideClick = (event) => {
      if (event.target.id !== "dropdownmodule") {
        if (
          DropDownRef.current &&
          !DropDownRef.current.contains(event.target)
        ) {
          setisDropDown(false);
        }
      }
    };
    document.addEventListener("click", handleOutSideClick);
    return () => {
      document.removeEventListener("click", handleOutSideClick);
    };
  }, []);

  return (
    <>
      <div className="z-3 ">
        {<Sidebar />}
        <div className="asside">
          <div className="about-first">
            <div className="row">
              <div className="col-4 mb-24">
                <div className="bg-box-new h-auto mb-24">
                  <div className="pro-add-new">
                    <h4>Select Module</h4>
                    <Link
                      type="button"
                      className="btn add-new"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalmodule"
                    >
                      Add Module
                    </Link>
                    {/* <!-- Add Module --> */}
                    <div
                      className="modal fade"
                      id="exampleModalmodule"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Module
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div className="user-details">
                              <div>
                                <div className="form-floating mb-4 mt-2">
                                  <select
                                    className="form-select form-control"
                                    id="floatingSelectGrid"
                                    aria-label="Floating label select example"
                                    onChange={(e) =>
                                      setModule({
                                        ...Module,
                                        technology_id: e.target.value,
                                      })
                                    }
                                  >
                                    <option>Select Technology</option>
                                    {Techs?.map((item) => {
                                      return (
                                        <option key={item.id} value={item.id}>
                                          {item.technology}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label for="floatingSelectGrid">
                                    Select Technology
                                  </label>
                                </div>
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onFocus={() => setisFouse(true)}
                                    autoComplete="off"
                                    value={Module.module}
                                    onChange={(e) => {
                                      setisDropDown(true);
                                      setisComplete(true);
                                      setModule({
                                        ...Module,
                                        module: e.target.value,
                                      });
                                    }}
                                  />
                                  <label for="floatingInput">Module</label>
                                  {Module?.module?.length &&
                                  isFouse &&
                                  isComplete &&
                                  isDropDown ? (
                                    <div
                                      class="position-absolute z-3  shadow-sm  rounded-2   h-auto  w-100  "
                                      ref={DropDownRef}
                                      style={{
                                        zIndex: "500",
                                        backgroundColor: "#F5F5F9",
                                      }}
                                    >
                                      <div className="dropdown h-100 w-100 d-flex  flex-column gap-1 pt-2">
                                        {SuggectionForModule?.map(
                                          (item, index) => {
                                            if (
                                              item
                                                ?.toLowerCase()
                                                ?.includes(
                                                  Module?.module?.toLowerCase()
                                                )
                                            )
                                              return (
                                                <div
                                                  key={index * 5}
                                                  className="p-1"
                                                  style={{ cursor: "pointer" }}
                                                  id="dropdownmodule"
                                                  onClick={() => {
                                                    setisComplete(false);
                                                    setModule({
                                                      ...Module,
                                                      module: item,
                                                    });
                                                  }}
                                                >
                                                  {item}
                                                </div>
                                              );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="form-floating mb-4">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    value={Module.hours_number}
                                    onChange={(e) =>
                                      setModule({
                                        ...Module,
                                        hours_number: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">No of hours</label>
                                </div>
                                <div className="form-floating mb-4">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    value={Module.prize}
                                    onChange={(e) =>
                                      setModule({
                                        ...Module,
                                        prize: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Prize</label>
                                </div>
                                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                                  <button
                                    className="btn btn-upload"
                                    onClick={handleAddModule}
                                  >
                                    Save changes
                                  </button>
                                  <button
                                    className="btn btn-reset me-0"
                                    data-bs-dismiss="modal"
                                    id="cancelMod"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--End Add Module --> */}
                  </div>
                  {/* <!-- Filter Modal --> */}
                  <div
                    className="modal fade"
                    id="exampleModalfilter"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-sm">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Apply Filter
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="user-details">
                            {Technologys?.map((item) => {
                              return (
                                <div key={item?.id} className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={item?.technology}
                                    id="flexCheckChecked"
                                    onChange={(e) => {
                                      let check = e.currentTarget.checked;
                                      if (check)
                                        setFilter([
                                          ...Filter,
                                          e.currentTarget.value,
                                        ]);
                                      else
                                        setFilter(
                                          Filter.filter(
                                            (val) =>
                                              val != e.currentTarget.value
                                          )
                                        );
                                    }}
                                    checked={Filter?.some(
                                      (val) => val == item?.technology
                                    )}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckChecked"
                                  >
                                    {item?.technology}
                                  </label>
                                </div>
                              );
                            })}
                            <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                              <button
                                className="btn btn-reset"
                                data-bs-dismiss="modal"
                                id="cloeseFilter"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn btn-upload me-0"
                                onClick={() => {
                                  setIsUpdate(!IsUpdate);
                                  document
                                    .getElementById("cloeseFilter")
                                    .click();
                                }}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!--End Filter Modal --> */}
                  <div className="d-flex search-project justify-content-between align-items-center">
                    <div className="filter">
                      <Link
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalfilter"
                      >
                        <i className="fa-solid fa-filter"></i> Filters
                      </Link>
                    </div>
                    <div className="form-floating small-floating">
                      <input
                        type="text"
                        className="form-control py-2"
                        id="floatingInput"
                        placeholder="name@example.com"
                        onChange={handleSearchData}
                      />
                      <label for="floatingInput">Search Module</label>
                    </div>
                  </div>
                </div>
                <div className="all-project-table all-project-plus p-1">
                  {isLoad ? (
                    module?.length ? (
                      module.map((item) => {
                        if (
                          !ClientModule?.some(
                            (el) => el?.module == item?.module
                          )
                        )
                          return (
                            <div
                              className="video-conference h-auto mb-10"
                              key={item.id}
                            >
                              <div className="project-one p-0 mt-0 bg-transparent">
                                <div className="project-photo-discription">
                                  <div className="form-check plus-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="flexCheckChecked"
                                      onClick={(e) => {
                                        handleAddProject(
                                          item,
                                          e.currentTarget.checked
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label video-font-size"
                                      for="flexCheckChecked"
                                    >
                                      <b>{item?.module}</b>
                                    </label>
                                  </div>
                                </div>
                                <div className="price-hours">
                                  <h3>
                                    {Symbol ?? "$"}
                                    {handlePrizeGet(item?.module)}
                                  </h3>
                                  <h4>{handleHourseGet(item?.module)}</h4>
                                </div>
                              </div>
                              <div className="five-tech">
                                {item?.technology_names?.map((tech) => (
                                  <p key={tech.id}>
                                    <a
                                      className={
                                        activeLinks.includes(
                                          `${tech?.technology_name}${item?.id}`
                                        )
                                          ? "active"
                                          : ""
                                      }
                                      onClick={() => {
                                        let active = `${tech?.technology_name}${item?.id}`;
                                        handleLinkClick(active);
                                        let pair =
                                          item?.module + tech?.technology_ID;
                                        if (TechIds?.includes(pair)) {
                                          let demo = TechIds?.filter(
                                            (data) => data != pair
                                          );
                                          setTechIds(demo);
                                        } else {
                                          setTechIds((prev) => [...prev, pair]);
                                        }
                                      }}
                                    >
                                      {tech?.technology_name}
                                    </a>
                                  </p>
                                ))}
                              </div>
                            </div>
                          );
                      })
                    ) : (
                      <p>Module Not Available !</p>
                    )
                  ) : (
                    <div className="text-center ">
                      <div
                        className="spinner-border text-secondary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                  {/* Module */}
                  {/* Module */}
                </div>
              </div>
              <div className="col-8 mb-24">
                <div className="bg-box-new h-auto">
                  <div className="search-project quotation my-1">
                    <h5 className="me-2">Quotation :</h5>
                    <div className="form-floating small-floating me-2">
                      <input
                        type="text"
                        className="form-control py-2"
                        id="floatingInput"
                        placeholder="name@example.com"
                        value={ClientName}
                        onChange={(e) => setClientName(e.target.value)}
                      />
                      <label for="floatingInput">Clint Name</label>
                    </div>
                    <div className="form-floating small-floating me-2">
                      <input
                        type="text"
                        className="form-control py-2"
                        id="floatingInput"
                        placeholder="name@example.com"
                        value={Project}
                        onChange={(e) => setProject(e.target.value)}
                      />
                      <label for="floatingInput">Project Name</label>
                    </div>
                    <div className="form-check form-switch me-2">
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Base on Hours
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={BaseOnHourse}
                        onChange={() => setBaseOnHourse(!BaseOnHourse)}
                      />
                    </div>
                  </div>
                  <table className="table tablee mt-3 table-scroll small-first-col">
                    <thead>
                      <tr className="bg-temp">
                        <th scope="col">No</th>
                        <th scope="col">Module</th>
                        <th scope="col">No Of Hours</th>
                        <th scope="col">Prize</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody className="all-project-table body-half-screen">
                      {ClientModule?.length > 0 ? (
                        ClientModule?.map((item, index) => {
                          return (
                            <tr>
                              <th scope="row">{index + 1}</th>
                              <td>{item?.module}</td>
                              <td>{handleGetHourse(item?.module)}</td>
                              <td>
                                {Symbol ?? "$"}{" "}
                                {handleTotalClient(item?.module)}
                              </td>
                              <td>
                                <div className="icon-up-del justify-content-center">
                                  <Link
                                    to={"#"}
                                    onClick={() => {
                                      setClientTechs(
                                        ClientTechs?.filter(
                                          (val) => !val?.includes(item?.module)
                                        )
                                      );
                                      setClientModule(
                                        ClientModule.filter(
                                          (el) => el?.id != item?.id
                                        )
                                      );
                                      // setClientModule((prev) =>
                                      //   ClientModule.filter(
                                      //     (val, no) => index != no
                                      //   )
                                      // );
                                      toast.success(
                                        "Module Remove Successfully !"
                                      );
                                    }}
                                  >
                                    <i className="fa-solid fa-trash me-0"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <div className="mx-auto h3 my-4">
                            Please Select Module ....
                          </div>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr className="last-tr-project">
                        <th></th>
                        <td>
                          <b>Net Subtotal :</b>
                        </td>
                        <td>{handleTotalHourse()}</td>
                        <td>
                          {Symbol ?? "$"} {handleTotalPrice()}
                        </td>
                        <td>
                          <div className="save-next">
                            <Link onClick={HandleSubmitProject}>
                              Save & next
                            </Link>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div></div>
          {<Footer />}
        </div>
      </div>
    </>
  );
};

export default CreateProject;
