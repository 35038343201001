import React, { useEffect } from "react";
import logo from "../Image/logo2.svg";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { useSymbol } from "../provider/ContextProvider";
import { USER_INFO } from "../utils/Api";
import { GetDetailOfPrroject } from "../helper/Main";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";

const Invoice = () => {
  const [ProjectDetail, setProjectDetail] = useState("");
  const [userData, setUserData] = useState(null);
  const [isClick, setisClick] = useState(false);
  const { id } = useParams();
  const [isLoad, setisLoad] = useState(false);
  const { Symbol } = useSymbol();
  const [cookie] = useCookies(["users"]);
  const navigate = useNavigate();
  async function getProjectDetail() {
    try {
      setisLoad(false);
      let obj = {
        user_id: cookie.users,
      };

      const { data } = await axios.post(USER_INFO, obj);
      setUserData(data?.user);
      let SecObj = {
        user_id: cookie.users,
        project_id: id,
      };
      const ProDetail = await GetDetailOfPrroject(SecObj);
      setProjectDetail(ProDetail);
    } catch (error) {
    } finally {
      setisLoad(true);
    }
  }
  const handleDownloadPDF = () => {
    const capture = document.querySelector("#invoice");

    // When the image is loaded, capture it in the PDF

    window.print();

    // html2canvas(capture).then((canvas) => {
    //   const imageData = canvas.toDataURL("img/png");
    //   const doc = new jsPDF("p", "mm");
    //   const ComW = doc.internal.pageSize.getWidth();
    //   doc.addImage(imageData, "PNG", 0, 0, ComW, 0);
    //   doc.save(`${ProjectDetail?.client_project}.pdf`);

    navigate("/Project");
    // });

    // Log error if the image fails to load
  };
  useEffect(() => {
    getProjectDetail();
  }, []);
  return (
    <div>
      <div>
        {!isLoad ? (
          <div>
            <div
              className="d-flex justify-content-center  align-content-center"
              style={{ margin: "15vw 0" }}
            >
              <Bars
                height="80"
                width="80"
                color="#C256FA"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </div>
        ) : (
          <>
            <div>
              <div className=" bg-white" id="invoice">
                {/* <img src={logo} alt="" className="water-mark" /> */}
                <div className="d-flex justify-content-around pt-5 align-items-center ">
                  <div className="sidebar-logo ">
                    <img
                      src={userData?.ProfileImageURL}
                      alt=""
                      width={225}
                      onError={(event) => {
                        event.currentTarget.src = logo;
                        event.currentTarget.className = "error";
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column  ">
                    <p
                      className="bold"
                      style={{
                        fontSize: "2vw",
                        fontWeight: "600",
                        color: "#4d5f71",
                      }}
                    >
                      {userData?.organizations}
                    </p>
                    <p
                      className="bold"
                      style={{
                        fontSize: "1.4vw",
                        fontWeight: "500",
                        color: "#78848f",
                      }}
                    >
                      {userData?.address}
                    </p>
                    <p
                      className="bold"
                      style={{
                        fontSize: "1.4vw",
                        fontWeight: "500",
                        color: "#78848f",
                      }}
                    >
                      {userData?.city_name} {userData?.zip}
                    </p>
                    <p
                      className="bold"
                      style={{
                        fontSize: "1.4vw",
                        fontWeight: "500",
                        color: "#78848f",
                      }}
                    >
                      {userData?.email}
                    </p>
                  </div>
                </div>
                <div className="pt-5" style={{ margin: "35px 0" }}>
                  <table class="table text-center ">
                    <thead
                      style={{ backgroundColor: "#f7a51b", color: "#000" }}
                    >
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Module</th>
                        <th scope="col ">No of Hourse</th>
                        <th scope="col">Prize</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ProjectDetail?.MainData?.map((item, index) => {
                        return (
                          <tr key={item?.id}>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.module}</td>
                            <td>{item?.hourse}</td>
                            <td>{item?.prize}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="d-flex align-items-center  gap-5   subTotal">
                    <div className="text-center " style={{ width: "58%" }}>
                      Net SubTotal
                    </div>
                    <div style={{ width: "23%" }}>
                      {ProjectDetail?.total_hourse} h
                    </div>
                    <div>
                      {Symbol || "$"} {ProjectDetail?.total_price}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`d-flex justify-content-evenly   align-items-center  py-4 `}
              id="buttonHiden"
            >
              <Link
                to={"/Dashboard"}
                className="btn"
                style={{ backgroundColor: "#F7A51B", color: "#ffffff" }}
              >
                Back To Dashboard
              </Link>
              <button
                className="btn "
                style={{ backgroundColor: "#F7A51B", color: "#ffffff" }}
                onClick={handleDownloadPDF}
              >
                Download PDF
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Invoice;
