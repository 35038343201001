import React from 'react'
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import Footer from './Footer';
import support from '../Image/support.svg';

const Membership = () => {
  return (
    <div>
        {<Sidebar/>}
        <div className="asside">
            <div className='about-first'>
                <div className="row">
                    <div className="col-12 mb-24">
                        <div className="bg-box bg-transparent shadow-none pb-0">
                            <div className="about-page">
                            <h5 className='mb-0'>Membership</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-8'>
                        <div className="mb-24">
                            <div className="bg-box lifetime-member">
                                <div className="row">
                                    <div className="col-8 pe-0">
                                        <div className="bg-box shadow-none right-radius">
                                            <div className="life-time-membership">
                                                <h5>Lifetime Membership</h5>
                                                <p>Here, I focus on a range of items and features that we use in life without
                                                giving them a second thought such as Coca Cola, body muscles and holding
                                                ones own breath. Though, most of these notes are not fundamentally
                                                necessary, they are such that you can use them for a good laugh, at a drinks
                                                party or for picking up women or men.</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="full-access">
                                                        <p><i className="fa-solid fa-lock-open"></i>Full Access</p>
                                                        <p className='mb-0'><i className="fa-regular fa-user"></i>15 Members</p>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="full-access border-0 text-end">
                                                        <p><i className="fa-regular fa-star"></i>Access all Features</p>
                                                        <p className='mb-0'><i className="fa-solid fa-chart-line"></i>Lifetime Free Update</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 p-0">
                                        <div className="bg-box bg-transparent shadow-none d-flex align-items-center justify-content-center">
                                            <div className="about-page spl-padding">
                                            {/* <h5 className='mb-0'>Membership</h5> */}
                                            <h4>$<span className='spl-txt-usd'>899</span>USD</h4>
                                            <h6>5 Tips For Offshore Software Development</h6>
                                            <Link to={'#'} className='btn btn-login w-auto px-3'>buy Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-24">
                            <div className="bg-box lifetime-member">
                                <div className="row">
                                    <div className="col-8 pe-0">
                                        <div className="bg-box shadow-none right-radius">
                                            <div className="life-time-membership">
                                                <h5>Lifetime Membership</h5>
                                                <p>Here, I focus on a range of items and features that we use in life without
                                                giving them a second thought such as Coca Cola, body muscles and holding
                                                ones own breath. Though, most of these notes are not fundamentally
                                                necessary, they are such that you can use them for a good laugh, at a drinks
                                                party or for picking up women or men.</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="full-access">
                                                        <p><i className="fa-solid fa-lock-open"></i>Full Access</p>
                                                        <p className='mb-0'><i className="fa-regular fa-user"></i>15 Members</p>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="full-access border-0 text-end">
                                                        <p><i className="fa-regular fa-star"></i>Access all Features</p>
                                                        <p className='mb-0'><i className="fa-solid fa-chart-line"></i>Lifetime Free Update</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 p-0">
                                        <div className="bg-box bg-transparent shadow-none d-flex align-items-center justify-content-center">
                                            <div className="about-page spl-padding">
                                            {/* <h5 className='mb-0'>Membership</h5> */}
                                            <h4>$<span className='spl-txt-usd'>899</span>USD</h4>
                                            <h6>5 Tips For Offshore Software Development</h6>
                                            <Link to={'#'} className='btn btn-login w-auto px-3'>buy Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-24">
                            <div className="bg-box lifetime-member">
                                <div className="row">
                                    <div className="col-8 pe-0">
                                        <div className="bg-box shadow-none right-radius">
                                            <div className="life-time-membership">
                                                <h5>Lifetime Membership</h5>
                                                <p>Here, I focus on a range of items and features that we use in life without
                                                giving them a second thought such as Coca Cola, body muscles and holding
                                                ones own breath. Though, most of these notes are not fundamentally
                                                necessary, they are such that you can use them for a good laugh, at a drinks
                                                party or for picking up women or men.</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="full-access">
                                                        <p><i className="fa-solid fa-lock-open"></i>Full Access</p>
                                                        <p className='mb-0'><i className="fa-regular fa-user"></i>15 Members</p>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="full-access border-0 text-end">
                                                        <p><i className="fa-regular fa-star"></i>Access all Features</p>
                                                        <p className='mb-0'><i className="fa-solid fa-chart-line"></i>Lifetime Free Update</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 p-0">
                                        <div className="bg-box bg-transparent shadow-none d-flex align-items-center justify-content-center">
                                            <div className="about-page spl-padding">
                                            {/* <h5 className='mb-0'>Membership</h5> */}
                                            <h4>$<span className='spl-txt-usd'>899</span>USD</h4>
                                            <h6>5 Tips For Offshore Software Development</h6>
                                            <Link to={'#'} className='btn btn-login w-auto px-3'>buy Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-24">
                            <div className="bg-box lifetime-member">
                                <div className="row">
                                    <div className="col-8 pe-0">
                                        <div className="bg-box shadow-none right-radius">
                                            <div className="life-time-membership">
                                                <h5>Lifetime Membership</h5>
                                                <p>Here, I focus on a range of items and features that we use in life without
                                                giving them a second thought such as Coca Cola, body muscles and holding
                                                ones own breath. Though, most of these notes are not fundamentally
                                                necessary, they are such that you can use them for a good laugh, at a drinks
                                                party or for picking up women or men.</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="full-access">
                                                        <p><i className="fa-solid fa-lock-open"></i>Full Access</p>
                                                        <p className='mb-0'><i className="fa-regular fa-user"></i>15 Members</p>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="full-access border-0 text-end">
                                                        <p><i className="fa-regular fa-star"></i>Access all Features</p>
                                                        <p className='mb-0'><i className="fa-solid fa-chart-line"></i>Lifetime Free Update</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 p-0">
                                        <div className="bg-box bg-transparent shadow-none d-flex align-items-center justify-content-center">
                                            <div className="about-page spl-padding">
                                            {/* <h5 className='mb-0'>Membership</h5> */}
                                            <h4>$<span className='spl-txt-usd'>899</span>USD</h4>
                                            <h6>5 Tips For Offshore Software Development</h6>
                                            <Link to={'#'} className='btn btn-login w-auto px-3'>buy Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-24">
                            <div className="bg-box lifetime-member">
                                <div className="row">
                                    <div className="col-8 pe-0">
                                        <div className="bg-box shadow-none right-radius">
                                            <div className="life-time-membership">
                                                <h5>Lifetime Membership</h5>
                                                <p>Here, I focus on a range of items and features that we use in life without
                                                giving them a second thought such as Coca Cola, body muscles and holding
                                                ones own breath. Though, most of these notes are not fundamentally
                                                necessary, they are such that you can use them for a good laugh, at a drinks
                                                party or for picking up women or men.</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="full-access">
                                                        <p><i className="fa-solid fa-lock-open"></i>Full Access</p>
                                                        <p className='mb-0'><i className="fa-regular fa-user"></i>15 Members</p>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="full-access border-0 text-end">
                                                        <p><i className="fa-regular fa-star"></i>Access all Features</p>
                                                        <p className='mb-0'><i className="fa-solid fa-chart-line"></i>Lifetime Free Update</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 p-0">
                                        <div className="bg-box bg-transparent shadow-none d-flex align-items-center justify-content-center">
                                            <div className="about-page spl-padding">
                                            {/* <h5 className='mb-0'>Membership</h5> */}
                                            <h4>$<span className='spl-txt-usd'>899</span>USD</h4>
                                            <h6>5 Tips For Offshore Software Development</h6>
                                            <Link to={'#'} className='btn btn-login w-auto px-3'>buy Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="mb-24 top-membership-box">
                            <div className="bg-box h-auto">
                                <div className="support-section">
                                    <img src={support} alt="" />
                                    <h5>Support</h5>
                                    <p>According to us blisters are a very common thing and we come
                                        across them very often in our daily lives. It is a very common
                                        occurrence like cold or fever depending upon your lifestyle.</p>
                                    <Link to={'#'} className='btn btn-login w-auto px-3'>Contact Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {<Footer/>}
        </div>
    </div>
  )
}

export default Membership