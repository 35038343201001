import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.svg";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { SignUpUser } from "../helper/auth";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useCookies } from "react-cookie";
const Signup = () => {
  const [ShowPwd, setShowPwd] = useState(false);
  const [cookie] = useCookies(["users"]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const OnSignUp = (data) => {
    if (!data.check)
      return toast.error("Please agree privacy policy & terms !");
    toast
      .promise(SignUpUser(data), {
        success: "Register Successfully !",
        loading: "Wait Few Moment !",
        error: (er) => er,
      })
      .then(() => {
        reset();
        navigate("/");
      })
      .catch((er) => {});
  };
  const Eye = ShowPwd ? FaRegEyeSlash : FaRegEye;
  useLayoutEffect(() => {
    if (cookie.users) {
      window.location = "/Dashboard";
    }
  }, []);
  return (
    <div>
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="signin-page">
              <img src={logo} />
              <h2>Adventure starts here 🚀</h2>
              <p>Make your Quotation easy and accurate!</p>
              <form onSubmit={handleSubmit(OnSignUp)}>
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("username", {
                      required: true,
                    })}
                  />
                  <label htmlFor="floatingInput">Username</label>
                  {errors?.username && (
                    <p className="pt-1 px-2 text-danger ">
                      Username is required !
                    </p>
                  )}
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Mobile Number"
                    maxLength={10}
                    {...register("mobileNumber", {
                      required: true,
                    })}
                  />
                  <label htmlFor="floatingInput">Mobile Number </label>
                  {errors?.mobileNumber && (
                    <p className="pt-1 px-2 text-danger ">
                      Mobile Number is required !
                    </p>
                  )}
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("email", { required: true })}
                  />
                  <label htmlFor="floatingInput">Email</label>
                  {errors?.email && (
                    <p className="pt-1 px-2 text-danger ">
                      Email is required !
                    </p>
                  )}
                </div>
                <div className="form-floating position-relative ">
                  <input
                    type={ShowPwd ? "text" : "password"}
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    {...register("password", {
                      required: true,
                    })}
                  />
                  <label htmlFor="floatingPassword">Password</label>
                  <Eye
                    onClick={() => setShowPwd(!ShowPwd)}
                    className="position-absolute"
                    size={22}
                    style={{
                      right: "10px",
                      bottom: `${errors?.password ? "38px" : "15px"}`,
                      cursor: "pointer",
                    }}
                  />
                  {errors?.password && (
                    <p className="pt-1 px-2 text-danger ">
                      Password is required !
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-between align-content-center my-3">
                  <div className="form-check remember-me">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      {...register("check")}
                    />
                    <label className="form-check-label" htmlFor="gridCheck">
                      I agree to{" "}
                      <Link to={"/Agree-Privacy-Terms"} className="forgot-pass">
                        privacy policy & terms
                      </Link>
                    </label>
                  </div>
                </div>
                <input
                  type="submit"
                  className="btn btn-login"
                  placeholder="Sign Up"
                />
              </form>
              <div className="create-acc">
                <p>
                  Already have an account?{" "}
                  <Link to={"/"}> Sign in instead</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
