import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.svg";
import toast from "react-hot-toast";
import { SendMailApi } from "../helper/Main";
import { useCookies } from "react-cookie";

// program to generate random strings

// declare all characters
const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

function generateString(length) {
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

const VerifyEmail = () => {
  const [email, setemail] = useState("");
  const [cookie, setCookie] = useCookies(["email-verify"]);
  const navigate = useNavigate();
  const handleSendOtp = () => {
    if (!email) return toast.error("Please Enter Email !");
    const code = generateString(10);
    toast
      .promise(SendMailApi({ email, code }), {
        loading: "Wait Few Moment !",
        success: "Mail Send Successfully Check Your Mail !",
        error: (er) => er || "Email is not register !",
      })
      .then((val) => {
        setemail("");
        setCookie("email-verify", code);
      })
      .catch(() => {
        navigate("/");
      });
  };
  return (
    <div>
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="signin-page">
              <img src={logo} />
              <h2>Verify Email </h2>
              <p>Please fill your email !</p>
              <div>
                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                  <label htmlFor="floatingInput">Email</label>
                </div>

                <div className="d-flex justify-content-between align-content-center my-3"></div>
                <button
                  type="submit"
                  onClick={handleSendOtp}
                  className="btn btn-login"
                >
                  Submit Email
                </button>
              </div>
              <div className="create-acc">
                <p>
                  <Link to={"/"}> Back to Login ?</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
