import React, { createContext, useContext } from "react";
import { useState } from "react";

export const useCurrency = createContext();

const ContextProvider = ({ children }) => {
  const [Symbol, setSymbol] = useState("");

  return (
    <useCurrency.Provider value={{ setSymbol, Symbol }}>
      {children}
    </useCurrency.Provider>
  );
};

export const useSymbol = () => useContext(useCurrency);

export default ContextProvider;
