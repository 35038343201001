import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import About from "./components/About";
import Membership from "./components/Membership";
import Technology from "./components/Technology";
import Project from "./components/Project";
import CreateProject from "./components/CreateProject";
import Module from "./components/Module";
import Scrolltotop from "./Scrolltotop";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import { ToastProvider } from "./provider/ToastProvider";
import Contact from "./components/Contact";
import Terms from "./components/Terms";
import Refund from "./components/Refund";
import Policay from "./components/Policay";
import NotFound from "./components/NotFound";
import UpdateProject from "./components/UpdateProject";
import ContextProvider from "./provider/ContextProvider";
import Invoice from "./components/Invoice";
import ForgatPassword from "./components/ForgatPassword";
import PrivactyAndTerms from "./components/PrivactyAndTerms";
import VerifyEmail from "./components/VerifyEmail";

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <ToastProvider>
          <Router>
            <Scrolltotop />
            <Routes>
              <Route path="/" element={<Signin />} />
              <Route path="/Signup" element={<Signup />} />
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/About" element={<About />} />
              <Route path="/Membership" element={<Membership />} />
              <Route path="/Technology" element={<Technology />} />
              <Route path="/Module" element={<Module />} />
              <Route path="/Project" element={<Project />} />
              <Route path="/CreateProject" element={<CreateProject />} />
              <Route path="/UpdateProject/:id" element={<UpdateProject />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/Terms" element={<Terms />} />
              <Route path="/Refund" element={<Refund />} />
              <Route path="/Policy" element={<Policay />} />
              <Route path="/Invoice/:id" element={<Invoice />} />
              <Route path="/ForgatPassword/:id" element={<ForgatPassword />} />
              <Route path="/Verify-email" element={<VerifyEmail />} />
              <Route
                path="/Agree-Privacy-Terms"
                element={<PrivactyAndTerms />}
              />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </Router>
        </ToastProvider>
      </ContextProvider>
    </div>
  );
}

export default App;
