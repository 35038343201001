import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "./Sidebar";
import user from "../Image/user.png";
import Footer from "./Footer";
import { useCookies } from "react-cookie";
import axios from "axios";
import { COUNTRY, GET_COUNTRY, GET_STATE, MAIN, USER_INFO } from "../utils/Api";
import { UpdateUser, UploadImage } from "../helper/Main";
import toast from "react-hot-toast";
import { Bars } from "react-loader-spinner";

const About = () => {
  const [userDetails, setuserDetails] = useState(null);
  const [isLoad, setisLoad] = useState(false);
  const [Country, setCountry] = useState([]);
  const TimeZone = useMemo(
    () => [
      { id: 1, name: "(GMT-12:00) International Date Line West" },
      { id: 2, name: "(GMT-11:00) Midway Island" },
      { id: 3, name: "(GMT-10:00) Hawaii" },
      { id: 4, name: "(GMT-11:00) Niue" },
      { id: 5, name: "(GMT+4:00) Dubai, United Arab Emirates" },
      { id: 6, name: "(GMT+5:00) Maldives" },
      { id: 7, name: "(GMT+6:00) Novosibirsk, Russia" },
      { id: 8, name: "(GMT+7:00) Bangkok, Thailand" },
      { id: 9, name: "(GMT+8:00) Beijing, China" },
      { id: 10, name: "(GMT+9:00) Tokyo, Japan" },
      { id: 11, name: "(GMT+10:00) Sydney, Australia" },
      { id: 12, name: "(GMT+11:00) Magadan, Russia" },
      { id: 13, name: "(GMT+12:00) Suva, Fiji" },
      { id: 14, name: "(GMT+13:00) Tongatapu, Tonga" },
      { id: 15, name: "(GMT+14:00) Kiritimati, Kiribati" },
      { id: 16, name: "(GMT-12:00) Baker Island, United States" },
      { id: 17, name: "(GMT-11:00) Pago Pago, American Samoa" },
      { id: 18, name: "(GMT-10:00) Tahiti, French Polynesia" },
      { id: 19, name: "(GMT-9:00) Gambier Islands, French Polynesia" },
      { id: 20, name: "(GMT-8:00) Pitcairn Islands" },
      { id: 21, name: "(GMT+5:30) Mumbai, India" },
    ],
    []
  );
  const [currency, setCurrency] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [States, setStates] = useState([]);
  const [file, setfile] = useState(null);
  const [cookie] = useCookies(["users"]);
  async function getUserData() {
    try {
      let obj = {
        user_id: cookie.users,
      };
      const { data: Currency } = await axios.get(COUNTRY);
      const { data: country } = await axios.post(GET_COUNTRY);
      setCountry(country?.data);
      setCurrency(Currency);
      const { data } = await axios.post(USER_INFO, obj);
      if (data?.user?.country) {
        let obj = {
          country_id: data?.user?.country,
        };

        const { data: State } = await axios.post(GET_STATE, obj);
        setStates(State?.data);
      }
      setuserDetails(data.user);
    } catch (er) {
      console.log(er);
    } finally {
      setisLoad(true);
    }
  }

  const handleChnageFile = (e) => {
    let f = e.target.files[0];
    const form = new FormData();
    form.append("user_ID", cookie.users);
    form.append("profileImage", f);
    UploadImage(form).then((val) => {
      toast.success("Image Upload Successfully !");
      setisUpdate(!isUpdate);
    });
  };

  const handleGetState = async (id) => {
    try {
      let obj = {
        country_id: id,
      };
      const { data: State } = await axios.post(GET_STATE, obj);
      setStates(State?.data);
    } catch (er) {
      console.log(er);
    }
  };

  const handleClick = () => {
    const main = { ...userDetails, user_id: cookie.users };
    UpdateUser(main)
      .then((val) => {
        console.log(val);
        toast.success("Data Update successfully !");
        setisUpdate(!isUpdate);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  useEffect(() => {
    getUserData();
  }, [isUpdate]);
  console.log(userDetails?.ProfileImageURL);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                {isLoad ? (
                  <>
                    <div className="account-details">
                      <div className="about-page">
                        <h5>Account Details</h5>
                      </div>
                      <div className="upload-img">
                        <div className="show-img">
                          <img
                            src={isLoad ? userDetails?.ProfileImageURL : null}
                            alt=""
                            onError={(event) => {
                              event.currentTarget.src = user;
                              event.currentTarget.className = "error";
                            }}
                          />
                        </div>
                        <div className="allowed-ext">
                          <div className="upload-reset-btn">
                            <label htmlFor="image" className="btn btn-upload">
                              Upload new photo
                            </label>
                            <input
                              id="image"
                              type="file"
                              onChange={handleChnageFile}
                              hidden
                            />
                          </div>
                          <p>Allowed JPG, GIF or PNG. Max size of 800K</p>
                        </div>
                      </div>
                    </div>
                    <div className="user-details">
                      <div className="row">
                        <div className="form-floating lable-col col-md-6 mb-4">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={userDetails?.username}
                            name="username"
                            onChange={(e) =>
                              setuserDetails({
                                ...userDetails,
                                username: e.target.value,
                              })
                            }
                          />
                          <label for="floatingInput">First Name</label>
                        </div>
                        <div className="form-floating lable-col col-md-6 mb-4">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={userDetails?.last_name}
                            name="last_name"
                            onChange={(e) =>
                              setuserDetails({
                                ...userDetails,
                                last_name: e.target.value,
                              })
                            }
                          />
                          <label for="floatingInput">Last Name</label>
                        </div>
                        <div className="form-floating lable-col col-md-6 mb-4">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={userDetails?.email}
                            name="email"
                            onChange={(e) =>
                              setuserDetails({
                                ...userDetails,
                                email: e.target.value,
                              })
                            }
                          />
                          <label for="floatingInput">E-mail</label>
                        </div>
                        <div className="form-floating lable-col col-md-6 mb-4">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={userDetails?.organizations}
                            name="organizations"
                            onChange={(e) =>
                              setuserDetails({
                                ...userDetails,
                                organizations: e.target.value,
                              })
                            }
                          />
                          <label for="floatingInput">Organization</label>
                        </div>
                        <div className="form-floating lable-col col-md-6 mb-4">
                          <input
                            type="number"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={userDetails?.mobile_number}
                            name="mobile_number"
                            onChange={(e) =>
                              setuserDetails({
                                ...userDetails,
                                mobile_number: e.target.value,
                              })
                            }
                          />
                          <label for="floatingInput">Phone Number</label>
                        </div>
                        <div className="form-floating lable-col col-md-6 mb-4">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={userDetails?.address}
                            name="address"
                            onChange={(e) =>
                              setuserDetails({
                                ...userDetails,
                                address: e.target.value,
                              })
                            }
                          />
                          <label for="floatingInput">Address</label>
                        </div>
                        <div className="form-floating lable-col col-md-6 mb-4">
                          <select
                            className="form-select form-control"
                            id="floatingSelectGrid"
                            aria-label="Floating label select example"
                            value={userDetails?.country}
                            name="country"
                            onChange={(e) => {
                              setuserDetails({
                                ...userDetails,
                                country: e.target.value,
                              });
                              handleGetState(e.target.value);
                            }}
                          >
                            <option>USA</option>
                            {Country?.map((val) => {
                              return (
                                <option
                                  key={val.id * 5}
                                  value={val?.id}
                                  selected={val?.id == userDetails?.country}
                                >
                                  {val?.name}
                                </option>
                              );
                            })}
                          </select>
                          <label for="floatingSelectGrid">Country</label>
                        </div>

                        <div className="form-floating lable-col col-md-6 mb-4">
                          <input
                            type="number"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={userDetails?.zip}
                            name="zip"
                            onChange={(e) =>
                              setuserDetails({
                                ...userDetails,
                                zip: e.target.value,
                              })
                            }
                          />
                          <label for="floatingInput">Zip Code</label>
                        </div>
                        <div className="form-floating lable-col col-md-6 mb-4">
                          <select
                            className="form-select form-control"
                            id="floatingSelectGrid"
                            aria-label="Floating label select example"
                            value={userDetails?.state}
                            name="state"
                            onChange={(e) =>
                              setuserDetails({
                                ...userDetails,
                                state: e.target.value,
                              })
                            }
                          >
                            <option value="">Select State </option>
                            {States?.map((val) => {
                              return (
                                <option
                                  value={val?.id}
                                  selected={val?.id == userDetails?.state}
                                >
                                  {val?.name}
                                </option>
                              );
                            })}
                          </select>
                          <label for="floatingInput">State</label>
                        </div>
                        <div className="form-floating lable-col col-md-6 mb-4">
                          <select
                            className="form-select form-control"
                            id="floatingSelectGrid"
                            aria-label="Floating label select example"
                            value={userDetails?.language}
                            name="language"
                            onChange={(e) => {
                              setuserDetails({
                                ...userDetails,
                                language: e.target.value,
                              });
                            }}
                          >
                            <option value="">Select Language</option>
                            <option
                              value="English"
                              selected={userDetails?.language == "English"}
                            >
                              English
                            </option>
                            <option
                              value="Hindi"
                              selected={userDetails?.language == "Hindi"}
                            >
                              Hindi
                            </option>
                            <option
                              value="Gujrati"
                              selected={userDetails?.language == "Gujrati"}
                            >
                              Gujrati
                            </option>
                          </select>
                          <label for="floatingSelectGrid">Language</label>
                        </div>
                        <div className="form-floating lable-col col-md-6 mb-4">
                          <select
                            className="form-select form-control"
                            id="floatingSelectGrid"
                            aria-label="Floating label select example"
                            value={userDetails?.timezone}
                            name="timezone"
                            onChange={(e) =>
                              setuserDetails({
                                ...userDetails,
                                timezone: e.target.value,
                              })
                            }
                          >
                            <option>
                              (GMT-11:00) International Date Line West
                            </option>
                            {TimeZone.map((item) => {
                              return (
                                <option
                                  key={item.id}
                                  value={item.name}
                                  selected={item.name == userDetails?.timezone}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                          <label for="floatingSelectGrid">Timezone</label>
                        </div>
                        <div className="form-floating lable-col col-md-6 mb-4">
                          <select
                            className="form-select form-control"
                            id="floatingSelectGrid"
                            aria-label="Floating label select example"
                            value={userDetails?.currency}
                            name="currency"
                            onChange={(e) =>
                              setuserDetails({
                                ...userDetails,
                                currency: e.target.value,
                              })
                            }
                          >
                            <option>USD</option>
                            {currency?.map((val) => {
                              return (
                                <option
                                  key={val.id}
                                  value={val?.currency_name}
                                  selected={
                                    val?.currency_name == userDetails?.currency
                                  }
                                >
                                  {val?.currency_name}
                                </option>
                              );
                            })}
                          </select>
                          <label for="floatingSelectGrid">Currency</label>
                        </div>
                        <div className="upload-reset-btn mb-0">
                          <button
                            className="btn btn-upload"
                            onClick={handleClick}
                          >
                            Save changes
                          </button>
                          <button className="btn btn-reset">Reset</button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className=" d-flex justify-content-center  align-items-center "
                    style={{ height: "60vh" }}
                  >
                    <Bars
                      height="50"
                      width="50"
                      color="#C256FA"
                      ariaLabel="bars-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default About;
