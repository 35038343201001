import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import laptop from "../Image/man-with-laptop-light.png";

import { useCookies } from "react-cookie";
import { ShowDetailOfDashboard } from "../helper/Main";
import toast from "react-hot-toast";
import {
  AddModuleApi,
  AddTechnology,
  GetModuleAPi,
  GetTechnology,
} from "../helper/auth";
import ProjectDash from "./ProjectDash";
import axios from "axios";
import { USER_INFO } from "../utils/Api";

const Dashboard = () => {
  const [cookie] = useCookies(["users"]);
  const [Techs, setTechs] = useState([]);
  const [UserData, setUserData] = useState(null);
  const [AddModule, setAddModule] = useState(null);
  const [SuggectionForModule, setSuggectionForModule] = useState([]);
  const [AllModule, setAllModule] = useState([]);
  const [isFouse, setisFouse] = useState(false);
  const [isComplete, setisComplete] = useState(false);
  const navigate = useNavigate();
  const [Module, setModule] = useState({
    user_id: cookie.users,
    technology_id: null,
    module: null,
    hours_number: null,
    prize: null,
  });
  const [isUpdate, setisUpdate] = useState(false);
  const [Technology, setTechnology] = useState({
    user_id: cookie.users,
    technology: null,
    hours: null,
  });
  const [details, setdetails] = useState(null);
  const [isDropDown, setisDropDown] = useState(false);
  const DropDownRef = useRef(null);
  const getData = async () => {
    try {
      let obj = {
        user_id: cookie.users,
      };
      const { data: user } = await axios.post(USER_INFO, obj);
      setUserData(user.user);
      const data = await ShowDetailOfDashboard(obj);
      setdetails(data);
    } catch (error) {}
  };

  const GetTechS = async () => {
    try {
      let obj = {
        user_id: cookie.users,
      };

      const data = await GetTechnology(obj);
      const Mod = await GetModuleAPi(obj);
      let SMOD = Mod.data?.map((item) => item?.module.trim());
      let demo = [...new Set(SMOD)];
      setSuggectionForModule(demo);
      setTechs(data.data);
    } catch (er) {}
  };

  const handleAddTechnology = () => {
    toast
      .promise(AddTechnology(Technology), {
        loading: "Wait Few Moment !",
        success: "Technology Add Successfully !",
        error: (er) => er,
      })
      .then((val) => {
        setTechnology({ ...Technology, technology: "", hours: "" });
        document.getElementById("cancelTech").click();
        document.getElementById("tech").value = "";
        document.getElementById("hourse").value = "";
        setisUpdate(!isUpdate);
      });
  };
  const handleAddModule = () => {
    if (
      !Module?.technology_id ||
      !Module?.module ||
      !Module?.hours_number ||
      !Module?.prize
    ) {
      return toast.error("All field is required !");
    }
    toast
      .promise(AddModuleApi(Module), {
        loading: "Wait Few Moment !",
        success: "Technology Add Successfully !",
        error: (er) => er,
      })
      .then((val) => {
        setModule({
          ...Module,
          technology_id: "",
          module: "",
          hours_number: "",
          prize: "",
        });
        document.getElementById("selectModule").value = "";
        document.getElementById("cancelMod").click();
        setisUpdate(!isUpdate);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getData();
    GetTechS();
  }, [isUpdate]);
  useEffect(() => {
    const handleOutSideClick = (event) => {
      if (event.target.id !== "dropdownmodule") {
        if (
          DropDownRef.current &&
          !DropDownRef.current.contains(event.target)
        ) {
          setisDropDown(false);
        }
      }
    };
    document.addEventListener("click", handleOutSideClick);
    return () => {
      document.removeEventListener("click", handleOutSideClick);
    };
  }, []);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="">
            <div className="row">
              <div className="col-6 mb-24">
                <div className="bg-box">
                  <div className="welcome-section">
                    <div className="welcome-text">
                      <h5>Welcome to Quick Maker 🎉</h5>
                      <p>Make your Quotation easy and accurate!</p>
                    </div>
                    <div className="welcome-img">
                      <img src={laptop} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2 mb-24">
                <div className="bg-box">
                  <div className="technology">
                    <div className="add-tech-box">
                      <button
                        type="button"
                        className="btn add-tech"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModaltechnology"
                      >
                        <i className="fa-solid fa-plus"></i>
                      </button>
                    </div>
                    <div className="add-technology">
                      <p>
                        Add Technology{" "}
                        <span className="add-tech-text">
                          ({details?.Counttechnology || 0})
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Add Technology --> */}
              <div
                className="modal fade"
                id="exampleModaltechnology"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Add Technology
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="user-details">
                        <div>
                          <div className="form-floating mb-4 mt-2">
                            <input
                              type="text"
                              className="form-control"
                              id="tech"
                              placeholder="name@example.com"
                              value={Technology?.technology}
                              onChange={(e) => {
                                setTechnology({
                                  ...Technology,
                                  technology: e.target.value,
                                });
                              }}
                            />
                            <label for="floatingInput">Technology</label>
                          </div>
                          <div className="form-floating mb-4">
                            <input
                              type="text"
                              className="form-control"
                              id="hourse"
                              placeholder="name@example.com"
                              value={Technology?.hours}
                              onChange={(e) => {
                                setTechnology({
                                  ...Technology,
                                  hours: e.target.value,
                                });
                              }}
                            />
                            <label for="floatingInput">Per Hours Rate</label>
                          </div>
                          <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                            <button
                              className="btn btn-upload"
                              onClick={handleAddTechnology}
                            >
                              Save changes
                            </button>
                            <button
                              className="btn btn-reset me-0"
                              data-bs-dismiss="modal"
                              id="cancelTech"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--End Add Technology --> */}
              <div className="col-2 mb-24">
                <div className="bg-box">
                  <div className="technology">
                    <div className="add-tech-box">
                      <button
                        type="button"
                        className="btn add-mod"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalmodule"
                      >
                        <i className="fa-solid fa-plus"></i>
                      </button>
                    </div>
                    <div className="add-technology">
                      <p>
                        Add Module{" "}
                        <span className="add-mod-text">
                          ({details?.Module || 0})
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Add Module --> */}
              <div
                className="modal fade"
                id="exampleModalmodule"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Add Module
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="user-details">
                        <div>
                          <div className="form-floating mb-4 mt-2">
                            <select
                              className="form-select form-control"
                              id="selectModule"
                              aria-label="Floating label select example"
                              onChange={(e) =>
                                setModule({
                                  ...Module,
                                  technology_id: e.target.value,
                                })
                              }
                            >
                              <option selected="selected" value="">
                                Select Technology
                              </option>
                              {Techs?.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.technology}
                                  </option>
                                );
                              })}
                            </select>
                            <label for="floatingSelectGrid">
                              Select Technology
                            </label>
                          </div>
                          <div className="form-floating mb-4">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="name@example.com"
                              onFocus={() => setisFouse(true)}
                              autoComplete="off"
                              value={Module.module}
                              onChange={(e) => {
                                setisDropDown(true);
                                setisComplete(true);
                                setModule({
                                  ...Module,
                                  module: e.target.value,
                                });
                              }}
                              // onChange={(e) =>
                              //   setModule({ ...Module, module: e.target.value })
                              // }
                            />
                            <label for="floatingInput">Module</label>
                            {Module?.module?.length &&
                            isFouse &&
                            isComplete &&
                            isDropDown ? (
                              <div
                                class="position-absolute z-3  shadow-sm  rounded-2   h-auto  w-100  "
                                ref={DropDownRef}
                                style={{
                                  zIndex: "500",
                                  backgroundColor: "#F5F5F9",
                                }}
                              >
                                <div className="dropdown h-100 w-100 d-flex  flex-column gap-1 pt-2">
                                  {SuggectionForModule?.map((item, index) => {
                                    if (
                                      item
                                        ?.toLowerCase()
                                        ?.includes(
                                          Module?.module?.toLowerCase()
                                        )
                                    )
                                      return (
                                        <div
                                          key={index * 5}
                                          className="p-1"
                                          id="dropdownmodule"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setisComplete(false);
                                            setModule({
                                              ...Module,
                                              module: item,
                                            });
                                          }}
                                        >
                                          {item}
                                        </div>
                                      );
                                  })}
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="form-floating mb-4">
                            <input
                              type="number"
                              className="form-control"
                              id="floatingInput"
                              placeholder="name@example.com"
                              value={Module.hours_number}
                              onChange={(e) =>
                                setModule({
                                  ...Module,
                                  hours_number: e.target.value,
                                })
                              }
                            />
                            <label for="floatingInput">No of hours</label>
                          </div>
                          <div className="form-floating mb-4">
                            <input
                              type="number"
                              className="form-control"
                              id="floatingInput"
                              placeholder="name@example.com"
                              value={Module.prize}
                              onChange={(e) =>
                                setModule({ ...Module, prize: e.target.value })
                              }
                            />
                            <label for="floatingInput">Prize</label>
                          </div>
                          <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                            <button
                              className="btn btn-upload"
                              onClick={handleAddModule}
                            >
                              Save changes
                            </button>
                            <button
                              className="btn btn-reset me-0"
                              data-bs-dismiss="modal"
                              id="cancelMod"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--End Add Module --> */}
              <div className="col-2 mb-24">
                <div className="bg-box">
                  <div className="technology">
                    <div className="add-tech-box">
                      <p
                        onClick={() => {
                          if (
                            UserData?.organizations &&
                            UserData?.email &&
                            UserData?.state &&
                            UserData?.zip
                          ) {
                            navigate("/CreateProject");
                          } else {
                            toast.error("Please Submit All Details !");
                            navigate("/About");
                          }
                        }}
                        type="button"
                        className="btn add-pro"
                      >
                        <i className="fa-solid fa-plus"></i>
                      </p>
                    </div>
                    <div className="add-technology">
                      <p>
                        Create Project{" "}
                        <span className="add-pro-text">
                          ({details?.Project || 0})
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Project Info Start */}
              <ProjectDash />
              {/* Project Info End */}
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default Dashboard;
