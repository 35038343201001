import React from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import ProjectDash from "./ProjectDash";

const Project = () => {
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <ProjectDash />
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default Project;
