import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.svg";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { SignInUser } from "../helper/auth";
import { useCookies } from "react-cookie";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
const Signin = () => {
  const [ShowPwd, setShowPwd] = useState(false);
  const [check, setcheck] = useState(true);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [cookie, setCookies] = useCookies(["users"]);
  const OnSubmit = (data) => {
    const { ...obj } = data;
    toast
      .promise(SignInUser(obj), {
        loading: "Wait Few Moment !",
        success: "Login Successfully !",
        error: (er) => er,
      })
      .then((val) => {
        let expirationDate = new Date();
        check
          ? expirationDate.setMonth(expirationDate.getMonth() + 1)
          : expirationDate.setMonth(expirationDate.getHours() + 1);
        setCookies("users", val.userId, { expires: expirationDate });
        navigate("/Dashboard");
      })
      .catch((er) => {
        console.log(er);
      });
  };
  useLayoutEffect(() => {
    if (cookie.users) window.location = "/Dashboard";
  }, []);
  const Eye = ShowPwd ? FaRegEyeSlash : FaRegEye;
  return (
    <div>
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="signin-page">
              <img src={logo} />
              <h2>Welcome to Quick Make! 👋</h2>
              <p>Please sign-in to your account and start the adventure</p>
              <form onSubmit={handleSubmit(OnSubmit)}>
                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("email", { required: true })}
                  />
                  <label htmlFor="floatingInput">Email</label>
                  {errors?.email && (
                    <p className="pt-1 px-2 text-danger ">
                      Email is required !
                    </p>
                  )}
                </div>
                <div className="form-floating position-relative ">
                  <input
                    type={ShowPwd ? "text" : "password"}
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    {...register("password", { required: true })}
                  />
                  <Eye
                    onClick={() => setShowPwd(!ShowPwd)}
                    className="position-absolute"
                    size={22}
                    style={{
                      right: "10px",
                      bottom: `${errors?.password ? "38px" : "15px"}`,
                      cursor: "pointer",
                    }}
                  />
                  <label htmlFor="floatingPassword">Password</label>
                  {errors?.password && (
                    <p className="pt-1 px-2 text-danger ">
                      Password is required !
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-between align-content-center my-3">
                  <div className="form-check remember-me">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      checked={check}
                      onChange={() => setcheck(!check)}
                    />
                    <label className="form-check-label" htmlFor="gridCheck">
                      Remember me
                    </label>
                  </div>
                  <Link to={"/Verify-email"} className="forgot-pass">
                    Forgot Password?
                  </Link>
                </div>
                <button type="submit" className="btn btn-login">
                  Sign in
                </button>
              </form>
              <div className="create-acc">
                <p>
                  New on our platform?{" "}
                  <Link to={"/Signup"}> Create an account</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
