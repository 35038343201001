import React from "react";
import logo from "../Image/404.png";
const NotFound = () => {
  return (
    <div
      style={{ backgroundColor: "#fff", height: "100vh" }}
      className="d-flex justify-content-center  align-items-center w-100"
    >
      <img src={logo} />
    </div>
  );
};

export default NotFound;
