export const MAIN = "https://quickmake-api.4born.in/api";
// export const MAIN = "http://localhost:4000/api";
export const AUTH = `${MAIN}`;
export const SIGNUP = `${AUTH}/user/register`;
export const SIGNIN = `${AUTH}/login/login`;
export const GET_TECHNOLOGY = `${AUTH}/UserTechnologies/getUserTechnologies`;
export const ADD_TECHNOLOGY = `${AUTH}/AddTechnology/technology`;
export const UPDATE_TECHNOLOGY = `${AUTH}/UpdateTechnology/updateTechnology`;
export const DELETE_TECHNOLOGY = `${AUTH}/DeleteTechnology/deleteTechnology`;
export const GET_MODULE = `${AUTH}/UserModle/getUserModule`;
export const ADD_MODULE = `${AUTH}/AddModule/module`;
export const UPDATE_MODULE = `${AUTH}/ModuleUpdates/moduleupdates`;
export const DELETE_MODULE = `${AUTH}/DeleteModule/deleteModule`;
export const SHOW_DASHBOARD_DETAIL = `${AUTH}/showtotalRecoder/checkUserId`;
export const USER_INFO = `${AUTH}/UserDetail/getUserByID`;
export const COUNTRY = `${AUTH}/country/country`;
export const IMAGE_UPLOAD = `${AUTH}/ProfileImageUpdate/updateProfileImage`;
export const UPDATE_USER = `${AUTH}/ProfileUpdate/updateUser`;
export const GET_MODULE_FOR_PROJECT = `${AUTH}/ModuleTechnology/module`;
export const ADD_PROJECT = `${AUTH}/AddProject/projects`;
export const GET_ALL_PROJECT = `${AUTH}/showAllProject/show`;
export const GET_PROJECT_DETAIL = `${AUTH}/ProjectShow/show`;
export const CONTACTUS = `${AUTH}/contact-us/contact`;
export const DELETE_PROJECT = `${AUTH}/DeleteProject/deleteProject`;
export const UPDATE_PROJECT = `${AUTH}/UpdateProject/update`;
export const GET_COUNTRY = `${AUTH}/get-country/get`;
export const GET_STATE = `${AUTH}/get-state/get`;
export const GET_SYMBOL = `${AUTH}/get-symbol/get`;
export const GET_MESSAGE = `${AUTH}/get-messages/get`;
export const SEND_MAIL = `${AUTH}/mail/send`;
export const CHANGE_PASSWORD = `${AUTH}/change/password`;
