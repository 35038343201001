import axios from "axios";
import {
  ADD_PROJECT,
  CONTACTUS,
  DELETE_PROJECT,
  GET_ALL_PROJECT,
  GET_MODULE_FOR_PROJECT,
  GET_PROJECT_DETAIL,
  IMAGE_UPLOAD,
  SEND_MAIL,
  SHOW_DASHBOARD_DETAIL,
  UPDATE_PROJECT,
  UPDATE_USER,
} from "../utils/Api";

export const ShowDetailOfDashboard = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(SHOW_DASHBOARD_DETAIL, obj);
      resolve(data);
    } catch (er) {
      console.log(er);
      reject("Something Wrong");
    }
  });
};

export const UploadImage = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(IMAGE_UPLOAD, obj);
      resolve();
    } catch (error) {
      console.log(error);
      reject("Something Wrong");
    }
  });
};

export const UpdateUser = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(UPDATE_USER, obj);
      console.log(data);
      resolve();
    } catch (er) {
      reject("Something Wrong");
    }
  });
};

export const GetModuleForProject = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(GET_MODULE_FOR_PROJECT, obj);
      resolve(data);
    } catch (er) {
      console.log(er);
      reject("Something Wrong");
    }
  });
};

export const AddProjectApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(ADD_PROJECT, obj);
      resolve(data);
    } catch (er) {
      console.log(er);
      reject("Something Wrong");
    }
  });
};

export const GetAllProject = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(GET_ALL_PROJECT, obj);
      console.log(data);
      resolve(data);
    } catch (error) {
      reject("Something Wrong");
    }
  });
};

export const GetDetailOfPrroject = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(GET_PROJECT_DETAIL, obj);
      resolve(data.data);
    } catch (er) {
      console.log(er);
      reject("Something Wrong");
    }
  });
};

export const ContactUsApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(CONTACTUS, obj);
      resolve("");
    } catch (er) {
      reject("SomeThing Wrong ");
    }
  });
};

export const DeleteProjectApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(DELETE_PROJECT, obj);
      resolve();
    } catch (er) {
      console.log(er);
      reject("SomeThing Wrong");
    }
  });
};

export const UpdateprojectApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(obj);
      const { data } = await axios.post(UPDATE_PROJECT, obj);
      console.log(data);
      resolve();
    } catch (er) {
      resolve();
    }
  });
};

export const SendMailApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(SEND_MAIL, obj);
      if (data.status == "error") {
        reject(data.message);
      }
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};
