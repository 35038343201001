import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import axios from "axios";
import { MAIN } from "../utils/Api";
import { Bars } from "react-loader-spinner";

const PrivactyAndTerms = () => {
  const [AllData, setAllData] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [Keys, setKeys] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(`${MAIN}/condition`);
        setAllData(Object.values(data.data));
        setKeys(Object.keys(data.data));
        setisLoad(true);
      } catch (er) {}
    })();
  }, []);
  return (
    <div>
      <div className="about-first bg-box px-5">
        <div className="row px-3 my-4">Welcome To Terms and Condition</div>
        {!isLoad ? (
          <div className="d-flex justify-content-center  align-items-center  ">
            <Bars
              height="50"
              width="50"
              color="#C256FA"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          AllData?.map((item, index) => {
            return (
              <div>
                <h3>{Keys[index]} </h3>
                <ul>
                  {item?.map((el) => {
                    return <li>{el.detail}</li>;
                  })}
                </ul>
              </div>
            );
          })
        )}

        {<Footer />}
      </div>
    </div>
  );
};

export default PrivactyAndTerms;
