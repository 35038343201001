import axios from "axios";
import {
  ADD_MODULE,
  ADD_TECHNOLOGY,
  CHANGE_PASSWORD,
  DELETE_MODULE,
  DELETE_TECHNOLOGY,
  GET_MODULE,
  GET_TECHNOLOGY,
  SIGNIN,
  SIGNUP,
  UPDATE_MODULE,
  UPDATE_TECHNOLOGY,
} from "../utils/Api";

export const SignUpUser = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { check, ...main } = obj;
      const { data } = await axios.post(SIGNUP, main);
      //   resolve(data.status);
      if (!data.status) {
        throw data.message;
      }
      resolve();
    } catch (er) {
      console.log(er);
      reject(er);
    }
  });
};

export const SignInUser = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(SIGNIN, obj);
      if (!data.status) throw data.message;
      resolve(data);
    } catch (er) {
      console.log(er);
      reject(er);
    }
  });
};

// Technology

export const GetTechnology = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(GET_TECHNOLOGY, obj);
      resolve(data);
    } catch (er) {
      reject("Something Wrong");
    }
  });
};

export const AddTechnology = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(ADD_TECHNOLOGY, obj);
      console.log(data);
      resolve(data);
    } catch (er) {
      console.log(er);
      reject("Something Wrong");
    }
  });
};

export const UpdateTechnology = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(obj);
      const { data } = await axios.post(UPDATE_TECHNOLOGY, obj);
      console.log(data);
      resolve();
    } catch (er) {
      console.log(er);
      reject("Something Wrong");
    }
  });
};

export const DeleteTechnology = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(DELETE_TECHNOLOGY, obj);
      resolve();
    } catch (er) {
      console.log(er);
      reject("Something Wrong");
    }
  });
};

export const GetModuleAPi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(GET_MODULE, obj);
      resolve(data);
    } catch (er) {
      console.log(er);
      reject("Something Wrong");
    }
  });
};

export const AddModuleApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(obj);
      const { data } = await axios.post(ADD_MODULE, obj);
      resolve();
    } catch (er) {
      console.log(er);
      reject("Module is Already Exist !");
    }
  });
};

export const UpdateModuleApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(UPDATE_MODULE, obj);
      resolve();
    } catch (er) {
      reject(er?.response?.data?.message || "Something Wrong !");
    }
  });
};

export const DeleteModuleApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(DELETE_MODULE, obj);
      resolve();
    } catch (er) {
      console.log(er);
      reject("Something Wrong");
    }
  });
};

export const ChangePassword = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(CHANGE_PASSWORD, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};
