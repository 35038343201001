import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import projectphoto from "../Image/project-photo.png";
import NotFound from "../Image/not_found.png";
import Flutter from "../Image/Flutter.png";
import { Bars } from "react-loader-spinner";
import Piechart from "./Piechart";
import { useCookies } from "react-cookie";
import logo from "../logo.svg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  DeleteProjectApi,
  GetAllProject,
  GetDetailOfPrroject,
} from "../helper/Main";
import toast from "react-hot-toast";
import axios from "axios";
import { USER_INFO } from "../utils/Api";
import { useSymbol } from "../provider/ContextProvider";

const ProjectDash = () => {
  const [Projects, setProjects] = useState([]);
  const { Symbol } = useSymbol();
  const [isLoad, setisLoad] = useState(false);
  const [ProjectId, setProjectId] = useState(0);
  const [ProjectDetail, setProjectDetail] = useState(null);
  const [ListOfTechnology, setListOfTechnology] = useState([]);
  const [SearchProject, setSearchProject] = useState([]);
  const [userData, setuserData] = useState(null);
  const [ChartName, setChartName] = useState([]);
  const [ChartDetail, setChartDetail] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [cookie] = useCookies(["users"]);
  const [Colors, setColors] = useState([]);
  const navigate = useNavigate();
  async function getProjects() {
    try {
      setisLoad(false);
      let obj = {
        user_id: cookie.users,
      };

      const { data } = await axios.post(USER_INFO, obj);
      setuserData(data?.user);
      const ProjectData = await GetAllProject(obj);
      setProjects(ProjectData.data.reverse());
      setSearchProject(ProjectData.data);
      setProjectId(ProjectData.data[0]?.id);
      let SecObj = {
        project_id: ProjectData.data[0]?.id,
      };
      const ProDetail = await GetDetailOfPrroject(SecObj);
      setProjectDetail(ProDetail);

      const MainArray = [];

      ProDetail?.MainData?.forEach((val) => {
        MainArray.push(val?.techs);
      });

      let ListOfTech = [];
      MainArray?.flat(Infinity)?.map((item) => {
        if (ListOfTech?.some((el) => el.tech_name == item?.tech_name)) {
          let index = ListOfTech.findIndex(
            (el) => el.tech_name == item.tech_name
          );
          ListOfTech[index].mod_price =
            Number(ListOfTech[index].mod_price) + Number(item?.mod_price);
          ListOfTech[index].mod_hourse =
            Number(ListOfTech[index].mod_hourse) + Number(item?.mod_hourse);
          ListOfTech[index].base_hourse +=
            Number(item.mod_hourse) * Number(item.tech_hourse);
        } else {
          let obj = {
            ...item,
            base_hourse: Number(item.mod_hourse) * Number(item.tech_hourse),
          };
          ListOfTech.push(obj);
        }
      });
      const SortData = ListOfTech?.map((item) => ({
        ...item,
        mod_price: Number(item?.mod_price),
      }))
        ?.sort((a, b) => a.mod_price - b.mod_price)
        .reverse();
      setListOfTechnology((prev) => SortData);
      let Detail = SortData?.map((item) => item?.tech_name);
      let NewDetail = SortData?.map((item) => Number(item?.mod_hourse));
      let ClientColor = SortData?.map((item) => item?.tech_color);
      setColors(ClientColor);
      setChartName(Detail);
      setChartDetail(NewDetail);
    } catch (er) {
    } finally {
      setTimeout(() => {
        setisLoad(true);
      }, 0);
    }
  }
  const HandleChageChart = async (id) => {
    let obj = {
      user_id: cookie.users,
      project_id: id,
    };
    setisLoad(false);
    const ProDetail = await GetDetailOfPrroject(obj);
    setProjectDetail(ProDetail);

    const MainArray = [];

    ProDetail?.MainData?.forEach((val) => {
      MainArray.push(val?.techs);
    });

    let ListOfTech = [];
    MainArray?.flat(Infinity)?.map((item) => {
      if (ListOfTech?.some((el) => el.tech_name == item?.tech_name)) {
        let index = ListOfTech.findIndex(
          (el) => el.tech_name == item.tech_name
        );
        ListOfTech[index].mod_price =
          Number(ListOfTech[index].mod_price) + Number(item?.mod_price);
        ListOfTech[index].mod_hourse =
          Number(ListOfTech[index].mod_hourse) + Number(item?.mod_hourse);
        ListOfTech[index].base_hourse +=
          Number(item.mod_hourse) * Number(item.tech_hourse);
      } else {
        let obj = {
          ...item,
          base_hourse: Number(item.mod_hourse) * Number(item.tech_hourse),
        };
        ListOfTech.push(obj);
      }
    });
    const SortData = ListOfTech?.map((item) => ({
      ...item,
      mod_price: Number(item?.mod_price),
    }))
      ?.sort((a, b) => a.mod_price - b.mod_price)
      .reverse();
    setListOfTechnology((prev) => SortData);
    let Detail = SortData?.map((item) => item?.tech_name);
    let NewDetail = SortData?.map((item) => Number(item?.mod_hourse));
    let ClientColor = SortData?.map((item) => item?.tech_color);
    setColors(ClientColor);
    setChartName(Detail);
    setChartDetail(NewDetail);
    setisLoad(true);
  };

  const handleDeleteProject = () => {
    if (ProjectId) {
      let obj = {
        project_id: ProjectId,
      };
      toast
        .promise(DeleteProjectApi(obj), {
          loading: "Wait Few Moment !",
          success: "Project Delete Successfully !",
          error: "Something Wrong !",
        })
        .then(() => {
          setisUpdate(!isUpdate);
        })
        .catch(() => {});
    }
  };
  const handleDownloadPDF = () => {
    const capture = document.querySelector("#invoice");
    html2canvas(capture).then((canvas) => {
      const ImageData = canvas.toDataURL("img/png");
      const doc = new jsPDF("p", "mm");
      const ComW = doc.internal.pageSize.getWidth();
      doc.addImage(ImageData, "PNG", 0, 0, ComW, 0);
      doc.save("Invoice.pdf");
      toast.success("Invoice Download Successfully !");
    });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    const newArray = Projects?.filter((item) =>
      item?.client_project?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    setSearchProject(newArray);
  };
  useEffect(() => {
    getProjects();
  }, [isUpdate]);
  return (
    <>
      <div className="col-4 mb-24">
        <div className="bg-box-new">
          <div className="pro-add-new">
            <h4>Project</h4>
            <p
              onClick={() => {
                if (
                  userData?.organizations &&
                  userData?.email &&
                  userData?.state &&
                  userData?.zip
                ) {
                  navigate("/CreateProject");
                } else {
                  toast.error("Please Submit All Details !");
                  navigate("/About");
                }
              }}
              type="button"
              className="btn add-new"
            >
              <i className="fa-solid fa-plus"></i> Add New
            </p>
          </div>
          <div className="search-project">
            <div className="form-floating small-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                onChange={handleSearch}
              />
              <label for="floatingInput">Search Project</label>
            </div>
          </div>
          <div className="all-project-table all-project-plus">
            {!isLoad ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "13  % 0",
                }}
              >
                <div>
                  <Bars
                    height="80"
                    width="80"
                    color="#c256fa"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              </div>
            ) : SearchProject?.length ? (
              SearchProject?.map((item) => {
                return (
                  <div
                    key={item?.id}
                    onClick={() => {
                      HandleChageChart(item?.id);
                    }}
                  >
                    <div className="project-one ">
                      <div className="project-photo-discription">
                        <div
                          className="image"
                          style={{
                            backgroundColor: item?.color || "#0f0f0f",
                          }}
                        />
                        <div className="pro-title-disc">
                          <p>{item?.MostUser}</p>
                          <h5>{item?.client_project}</h5>
                        </div>
                      </div>
                      <div className="price-hours d-flex">
                        <h3>
                          {Symbol || "$"}
                          {item?.total_price}
                        </h3>
                        <h4>{item?.total_hourse}h</h4>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px 0",
                }}
              >
                <p>Project Not Found</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-8 mb-24">
        {isLoad ? (
          ProjectId ? (
            <div className="bg-box h-auto">
              <div className="project-one bg-transparent mt-0 py-0 mb-3 px-0">
                <div className="project-photo-discription">
                  <div className="pro-title-disc">
                    <p>{ProjectDetail?.client_name}</p>
                    <h5>{ProjectDetail?.client_project}</h5>
                  </div>
                </div>
                <div className="edit-delete">
                  <Link
                    to={`/updateProject/${ProjectDetail?.id}`}
                    className="btn client-edit me-2"
                  >
                    Edit - {ProjectDetail?.id}
                  </Link>
                  <Link
                    className="btn client-delete"
                    onClick={handleDeleteProject}
                  >
                    Delete
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <Piechart
                    chartDetail={ChartDetail}
                    ChartName={ChartName}
                    ClinetColors={Colors}
                  />

                  <div className="quotation-amt">
                    <h3>
                      {Symbol || "$"} {ProjectDetail?.total_price}
                    </h3>
                    <p>Quotation Amount</p>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div>
                    {ListOfTechnology?.map((item) => {
                      return (
                        <Link to={"#"}>
                          <div className="project-one">
                            <div className="project-photo-discription">
                              <div
                                className="image"
                                style={{
                                  backgroundColor:
                                    item?.tech_color || "#0f0f0f",
                                }}
                              />
                              <div className="pro-title-disc">
                                <h5>{item?.tech_name}</h5>
                                <p className="mb-0">{item?.mod_hourse} Hours</p>
                              </div>
                            </div>
                            <div className="price-hours d-flex">
                              <h3>
                                {Symbol || "$"}
                                {item?.mod_price}
                              </h3>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
                <div className="text-center mt-4">
                  <Link
                    to={`/Invoice/${ProjectDetail?.id}`}
                    className="btn btn-pro"
                  >
                    View quotation
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-box h-auto ">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <img src={NotFound} alt="" width={500} />
                </div>
                <div>Project is not found</div>
              </div>
            </div>
          )
        ) : (
          <div className="bg-box h-50 ">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "13  % 0",
              }}
            >
              <div>
                <Bars
                  height="80"
                  width="80"
                  color="#c256fa"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectDash;
