import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../logo.svg";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ChangePassword } from "../helper/auth";

const ForgatPassword = () => {
  const [cookie, setCookie, removeCookie] = useCookies(["email-verify"]);
  const { register, reset, handleSubmit } = useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const handleChangePassword = (data) => {
    if (data.password != data.cpwd)
      return toast.error("Password or Confirm Password must be same !");
    toast
      .promise(
        ChangePassword({ id: id?.split("-")[1], password: data.password }),
        {
          loading: "Wait Few Moment !",
          success: "Password Change Successfully !",
          error: "Something Wrong !",
        }
      )
      .then(() => {
        reset();
        removeCookie("email-verify");
        navigate("/");
      });
  };
  useEffect(() => {
    if (!cookie["email-verify"]) return (window.location = "/");
    if (cookie["email-verify"] != id?.split("-")[0])
      return window.location("/");
  }, []);
  return (
    <div>
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="signin-page">
              <img src={logo} />
              <h2>ForgatPassword </h2>
              <p>Create New Password !</p>
              <form onSubmit={handleSubmit(handleChangePassword)}>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("password", { required: true })}
                  />
                  <label htmlFor="floatingInput">New Password</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingInput2"
                    placeholder="name@example.com"
                    {...register("cpwd", { required: true })}
                  />
                  <label htmlFor="floatingInput2">Confirm Password</label>
                </div>

                <div className="d-flex justify-content-between align-content-center my-3"></div>
                <button type="submit" className="btn btn-login">
                  Change Password
                </button>
              </form>
              <div className="create-acc">
                <p>
                  <Link to={"/Signup"}> Back to Login ?</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgatPassword;
