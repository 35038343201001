import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useCookies } from "react-cookie";
import NotFound from "../Image/not_found.png";
import { Bars } from "react-loader-spinner";
import {
  AddModuleApi,
  DeleteModuleApi,
  GetModuleAPi,
  GetTechnology,
  UpdateModuleApi,
} from "../helper/auth";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSymbol } from "../provider/ContextProvider";

const Module = () => {
  const [Filter, setFilter] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [Tech, setTech] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [MainMod, setMainMod] = useState([]);
  const { register, handleSubmit, reset } = useForm();
  const [currentPage, setcurrentPage] = useState(1);
  const [Mod, setMod] = useState([]);
  const [cookie] = useCookies(["users"]);
  const [recordsPerPage] = useState(10);
  const [nPage, setnPage] = useState([1]);
  const [UpdateModule, setUpdateModule] = useState(null);
  const [AddModule, setAddModule] = useState({});
  const [SuggectionForModule, setSuggectionForModule] = useState([]);
  const [isFouse, setisFouse] = useState(false);
  const [isComplete, setisComplete] = useState(false);
  const [isDropDown, setisDropDown] = useState(false);
  const DropDownRef = useRef(null);
  const { Symbol } = useSymbol();
  const GetModule = async () => {
    try {
      const obj = {
        user_id: cookie.users,
        Filtertechnology: Filter.join(","),
      };

      const Tech = await GetTechnology(obj);
      setTech(Tech.data);
      const data = await GetModuleAPi(obj);
      setMainMod(data.data);
      let SMOD = data.data?.map((item) => item?.module.trim());
      let demo = [...new Set(SMOD)];
      setSuggectionForModule(demo);
      const indexOfLastRecord = currentPage * recordsPerPage;
      const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      const currentRecords = data.data.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      );
      setMod(currentRecords);
      const nPages = Math.ceil(data.total_records / recordsPerPage);
      const num = [...Array(nPages + 1).keys()].slice(1);
      setnPage(num);
      setisLoad(true);
    } catch (er) {
      console.log(er);
    }
  };
  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = MainMod.slice(indexOfFirstRecord, indexOfLastRecord);
    setMod(currentRecords);
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    const newArray = MainMod.filter(
      (item) =>
        item.technology.toLowerCase().includes(val.toLowerCase()) ||
        item.module.toLowerCase().includes(val.toLowerCase())
    );
    setcurrentPage(1);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setMod(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };

  const handleAddModule = () => {
    if (
      AddModule?.technology &&
      AddModule?.module &&
      AddModule?.hours &&
      AddModule?.prize
    ) {
      const Main = {
        user_id: cookie.users,
        technology_id: AddModule.technology,
        module: AddModule.module,
        hours_number: AddModule.hours,
        prize: AddModule.prize,
      };

      toast
        .promise(AddModuleApi(Main), {
          loading: "Wait Few Moment !",
          success: "Module Add SuccessFully",
          error: (er) => er,
        })
        .then(() => {
          reset();
          document.getElementById("cancel").click();
          setisUpdate(!isUpdate);
          setAddModule({ technology: "", module: "", hours: "", prize: "" });
        })
        .catch(() => {});
    } else {
      return toast.error("All field is required !");
    }
  };

  const handleOnChange = (e) => {
    let check = e.currentTarget.checked;
    if (check) setFilter([...Filter, e.currentTarget.value]);
    else setFilter(Filter.filter((val) => val != e.currentTarget.value));
  };

  const handleUpdateModule = () => {
    const MainObj = {
      module_id: UpdateModule.id,
      module: UpdateModule.module,
      Technology_id: UpdateModule.technology_id,
      hours_number: UpdateModule.hours_number,
      prize: UpdateModule.prize,
    };

    toast
      .promise(UpdateModuleApi(MainObj), {
        loading: "Wait Few Moment !",
        success: "Module Update successfully !",
        error: (e) => e,
      })
      .then((val) => {
        document.getElementById("cancleEdit").click();
        setisUpdate(!isUpdate);
      })
      .catch(() => {});
  };

  const handleDeleteModule = (obj) => {
    DeleteModuleApi(obj)
      .then((val) => {
        toast.success("Module Delete Successfully !");
        setisUpdate(!isUpdate);
      })
      .catch(() => {});
  };

  useEffect(() => {
    GetModule();
  }, [isUpdate]);
  useEffect(() => {
    const handleOutSideClick = (event) => {
      if (event.target.id !== "dropdownmodule") {
        if (
          DropDownRef.current &&
          !DropDownRef.current.contains(event.target)
        ) {
          setisDropDown(false);
        }
      }
    };
    document.addEventListener("click", handleOutSideClick);
    return () => {
      document.removeEventListener("click", handleOutSideClick);
    };
  }, []);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>
                    Module <span>{MainMod?.length ?? 0}</span>
                  </p>
                  <Link
                    type="button"
                    className="btn add-new"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i className="fa-solid fa-plus"></i> Add New
                  </Link>
                </div>
                {/* <!-- Add Modal --> */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Module
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="user-details">
                          <div>
                            <div className="form-floating mb-4 mt-2">
                              <select
                                className="form-select form-control"
                                id="floatingSelectGrid"
                                aria-label="Floating label select example"
                                value={AddModule?.technology}
                                onChange={(e) =>
                                  setAddModule({
                                    ...AddModule,
                                    technology: e.target.value,
                                  })
                                }
                              >
                                <option>Select Technology</option>
                                {Tech?.map((val) => {
                                  return (
                                    <option key={val.id} value={val.id}>
                                      {val.technology}
                                    </option>
                                  );
                                })}
                              </select>
                              <label for="floatingSelectGrid">
                                Select Technology
                              </label>
                            </div>
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="name@example.com"
                                onFocus={() => setisFouse(true)}
                                autoComplete="off"
                                value={AddModule?.module}
                                onChange={(e) => {
                                  setisDropDown(true);
                                  setisComplete(true);
                                  setAddModule({
                                    ...AddModule,
                                    module: e.target.value,
                                  });
                                }}
                              />
                              <label for="floatingInput">Module</label>
                              {AddModule?.module?.length &&
                              isDropDown &&
                              isFouse &&
                              isComplete ? (
                                <div
                                  class="position-absolute z-3  shadow-sm  rounded-2   h-auto  w-100  "
                                  ref={DropDownRef}
                                  style={{
                                    zIndex: "500",
                                    backgroundColor: "#F5F5F9",
                                  }}
                                >
                                  <div className="dropdown h-100 w-100 d-flex  flex-column gap-1 pt-2">
                                    {SuggectionForModule?.map((item, index) => {
                                      if (
                                        item
                                          ?.toLowerCase()
                                          ?.includes(
                                            AddModule?.module?.toLowerCase()
                                          )
                                      )
                                        return (
                                          <div
                                            key={index * 5}
                                            className="p-1"
                                            style={{ cursor: "pointer" }}
                                            id="dropdownmodule"
                                            onClick={() => {
                                              setisComplete(false);
                                              setAddModule({
                                                ...AddModule,
                                                module: item,
                                              });
                                            }}
                                          >
                                            {item}
                                          </div>
                                        );
                                    })}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="form-floating mb-4 z-0 ">
                              <input
                                type="number"
                                className="form-control"
                                id="floatingInput"
                                placeholder="name@example.com"
                                value={AddModule?.hours}
                                onChange={(e) =>
                                  setAddModule({
                                    ...AddModule,
                                    hours: e.target.value,
                                  })
                                }
                              />
                              <label for="floatingInput">No of hours</label>
                            </div>
                            <div className="form-floating mb-4">
                              <input
                                type="number"
                                className="form-control"
                                id="floatingInput"
                                placeholder="name@example.com"
                                value={AddModule?.prize}
                                onChange={(e) =>
                                  setAddModule({
                                    ...AddModule,
                                    prize: e.target.value,
                                  })
                                }
                              />
                              <label for="floatingInput">
                                Prize ( in {Symbol ?? "$"} )
                              </label>
                            </div>
                            <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                              <button
                                className="btn btn-upload"
                                onClick={handleAddModule}
                              >
                                Save changes
                              </button>
                              <button
                                className="btn btn-reset me-0"
                                data-bs-dismiss="modal"
                                id="cancel"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--End Add Modal --> */}
                {/* <!-- Edit Modal --> */}
                <div
                  className="modal fade"
                  id="exampleModaledit"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Edit Module
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="user-details">
                          <div>
                            <div className="form-floating mb-4 mt-2">
                              <select
                                className="form-select form-control"
                                id="floatingSelectGrid"
                                aria-label="Floating label select example"
                                onChange={(e) =>
                                  setUpdateModule({
                                    ...UpdateModule,
                                    technology_id: e.target.value,
                                  })
                                }
                              >
                                <option>Select Technology</option>
                                {Tech?.map((val) => {
                                  return (
                                    <option
                                      key={val?.id}
                                      value={val?.id}
                                      selected={
                                        val?.technology ==
                                        UpdateModule?.technology
                                      }
                                    >
                                      {val?.technology}
                                    </option>
                                  );
                                })}
                              </select>
                              <label for="floatingSelectGrid">
                                Select Technology
                              </label>
                            </div>
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="name@example.com"
                                value={UpdateModule?.module}
                                onChange={(e) =>
                                  setUpdateModule({
                                    ...UpdateModule,
                                    module: e.target.value,
                                  })
                                }
                              />
                              <label for="floatingInput">Module</label>
                            </div>
                            <div className="form-floating mb-4">
                              <input
                                type="number"
                                className="form-control"
                                id="floatingInput"
                                placeholder="name@example.com"
                                value={UpdateModule?.hours_number}
                                onChange={(e) =>
                                  setUpdateModule({
                                    ...UpdateModule,
                                    hours_number: e.target.value,
                                  })
                                }
                              />
                              <label for="floatingInput">No of hours</label>
                            </div>
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="name@example.com"
                                value={UpdateModule?.prize}
                                onChange={(e) =>
                                  setUpdateModule({
                                    ...UpdateModule,
                                    prize: e.target.value,
                                  })
                                }
                              />
                              <label for="floatingInput">
                                Prize ( in {Symbol ?? "$"} )
                              </label>
                            </div>
                            <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                              <button
                                className="btn btn-upload"
                                onClick={handleUpdateModule}
                              >
                                Save changes
                              </button>
                              <button
                                className="btn btn-reset me-0"
                                data-bs-dismiss="modal"
                                id="cancleEdit"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--End Edit Modal --> */}
                {/* <!-- Filter Modal --> */}
                <div
                  className="modal fade"
                  id="exampleModalfilter"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Apply Filter
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="user-details">
                          {Tech?.map((val) => {
                            return (
                              <div key={val.id} className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={val.id}
                                  id="flexCheckDefault"
                                  onChange={handleOnChange}
                                  checked={Filter.includes(val.id.toString())}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  {val.technology}
                                </label>
                              </div>
                            );
                          })}

                          <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                            <button
                              className="btn btn-reset"
                              data-bs-dismiss="modal"
                              id="filterModel"
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-upload me-0"
                              onClick={() => {
                                setisUpdate(!isUpdate);
                                document.getElementById("filterModel").click();
                              }}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--End Filter Modal --> */}
                <div className="row mb-3 justify-content-between align-items-center">
                  <div className="col-4">
                    <div className="filter">
                      <Link
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalfilter"
                      >
                        <i className="fa-solid fa-filter"></i> Filters
                      </Link>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-floating small-floating">
                      <input
                        type="text"
                        className="form-control py-2"
                        id="floatingInput"
                        placeholder="name@example.com"
                        onChange={handleSearch}
                      />
                      <label for="floatingInput">Search</label>
                    </div>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Module</th>
                      <th scope="col">Technology</th>
                      <th scope="col">No of Hours</th>
                      <th scope="col">Prize</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoad ? (
                      Mod?.length ? (
                        Mod?.map((val, index) => {
                          return (
                            <tr key={val.id}>
                              <th scope="row">
                                {currentPage != 1
                                  ? 10 * (currentPage - 1) + index + 1
                                  : index + 1}
                              </th>
                              <td className="td-technology">{val.module}</td>
                              <td>{val.technology}</td>
                              <td>{val.hours_number}</td>
                              <td>
                                {Symbol || "$"} {val.prize}
                              </td>
                              <td>
                                <div className="icon-up-del">
                                  <Link
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModaledit"
                                    onClick={() => setUpdateModule(val)}
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                  </Link>
                                  <Link
                                    onClick={() =>
                                      handleDeleteModule({ module_id: val.id })
                                    }
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={6}>
                            <div className=" ">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column-reverse",
                                  alignItems: "center",
                                }}
                              >
                                <p>Module Not Found</p>
                                <div>
                                  <img src={NotFound} width={250} />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          <div className=" ">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                                alignItems: "center",
                              }}
                            >
                              <Bars
                                height="50"
                                width="50"
                                color="#C256FA"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div
                  className="pro-add-new px-0 mb-0 p
                t-3"
                >
                  <p>
                    {currentPage} - {nPage?.length} of {nPage?.length}
                  </p>
                  <nav aria-label="...">
                    <ul className="pagination pagination-sm mb-0">
                      {nPage?.map((val) => {
                        return (
                          <li
                            style={{ cursor: "pointer" }}
                            className={`page-item ${
                              currentPage == val && "active"
                            }`}
                            aria-current="page"
                            key={val}
                            onClick={() => handleChangePage(val)}
                          >
                            <span className="page-link">{val}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default Module;
