import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ContactUsApi } from "../helper/Main";
const Contact = () => {
  const { register, handleSubmit, reset } = useForm();
  const [isDisabled, setisDisable] = useState(false);
  const OnContactSubmit = (data) => {
    setisDisable(true);
    toast
      .promise(ContactUsApi(data), {
        loading: "Wait Few Moment !",
        success: "Thank you for ContectUs !",
        error: (er) => er,
      })
      .then(() => {
        reset();
        setisDisable(false);
      })
      .catch(() => {});
  };
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="container ">
              <div className="row  d-flex  justify-content-center">
                <div className="col-lg-6 col-md-8 col-12 my-3">
                  <div className="signin-page">
                    {/* <img src={logo} /> */}
                    <h2>Contact Us </h2>
                    <p>
                      Get in touch with us and let us know how we can help you.
                    </p>
                    <form onSubmit={handleSubmit(OnContactSubmit)}>
                      <div className="form-floating mb-4">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="name"
                          disabled={isDisabled}
                          {...register("name", { required: true })}
                        />
                        <label htmlFor="floatingInput">Name</label>
                      </div>
                      <div className="form-floating mb-4">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingInput"
                          placeholder="email"
                          disabled={isDisabled}
                          {...register("email", { required: true })}
                        />
                        <label htmlFor="floatingInput">Email</label>
                      </div>
                      <div className="form-floating mb-4">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="phone"
                          maxLength={10}
                          disabled={isDisabled}
                          {...register("phone", { required: true })}
                        />
                        <label htmlFor="floatingInput">Mobile Number</label>
                      </div>
                      <div className="form-floating position-relative ">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingPassword"
                          placeholder="subject"
                          disabled={isDisabled}
                          {...register("subject", { required: true })}
                        />

                        <label htmlFor="floatingPassword">Subject</label>
                      </div>
                      <div className="form-floating position-relative mt-4">
                        <textarea
                          className="form-control"
                          id="floatingPassword"
                          placeholder="Message"
                          disabled={isDisabled}
                          {...register("message", { required: true })}
                        ></textarea>

                        <label htmlFor="floatingPassword">Message</label>
                      </div>
                      <div className="d-flex justify-content-between align-content-center my-3"></div>
                      <button
                        type="submit"
                        className="btn btn-login"
                        disabled={isDisabled}
                      >
                        Contact us
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
    </div>
  );
};

export default Contact;
