import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const useVerify = () => {
  const [cookie] = useCookies(["users"]);
  const navi = useNavigate();
  useEffect(() => {
    if (!cookie.users) navi("/");
  }, []);
};

export default useVerify;
